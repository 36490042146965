/**
 * Function for work with the vuetify datePicker
 *
 * Test are available in "tests" folder
 */

/**
 *
 * @param date {string} a date string with the form 'yyyy-mm-dd' (ex : 2019-11-25)
 * @returns {{month: *, year: *, day: *}}
 */
function getSplittedDate (date) {
  let splitted = date.split('-')
  return {
    day: parseInt(splitted[2]),
    month: parseInt(splitted[1]),
    year: parseInt(splitted[0])
  }
}

/**
 * @param splittedDate
 * @returns {string}
 */
function reformSplittedDate (splittedDate) {
  if (splittedDate.day < 10) {
    splittedDate.day = `0${splittedDate.day}`
  }

  if (splittedDate.month < 10) {
    splittedDate.month = `0${splittedDate.month}`
  }
  return [splittedDate.year, splittedDate.month, splittedDate.day].join('-')
}

/**
 * for a splitted date (ex : 2019-02-14) return the next date (for the ex : 2019-02-14)
 * dont care about the number of day in the month
 * @param date {string} a date string with the form 'yyyy-mm-dd' (ex : 2019-11-25)
 * @returns {string}
 */
function getNextDate (date) {
  let splittedDate = getSplittedDate(date)
  if (splittedDate.day < 31) {
    splittedDate.day++
    return reformSplittedDate(splittedDate)
  } else if (splittedDate.month < 12) {
    splittedDate.day = 1
    splittedDate.month++
    return reformSplittedDate(splittedDate)
  } else {
    splittedDate.day = 1
    splittedDate.month = 1
    splittedDate.year++
    return reformSplittedDate(splittedDate)
  }
}

/**
 * @param date {Date}
 * @return {string}
 */
function dateToDatePickerDate (date) {
  let month = date.getMonth() + 1
  let day = date.getUTCDate()
  let year = date.getFullYear()

  const addZero = (value) => value < 10 ? `0${value}` : String(value)

  return `${year}-${addZero(month)}-${addZero(day)}`
}

/**
 * transform a date picker date to a insertion day date
 * @param datePickerDate
 * @returns {number}
 */
function datePickerToInsertionDay (datePickerDate) {
  return parseInt(datePickerDate.replace(/-/g, ''), 10)
}

export {
  getSplittedDate,
  reformSplittedDate,
  getNextDate,
  dateToDatePickerDate,
  datePickerToInsertionDay
}
