
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import { Autoplugs } from '../../../../../types/autoplugs_type'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import AutoplugForm from '@/components/KeystoneV2/Form/Autoplug/AutoplugForm.vue'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import AutoplugInfo from '@/components/KeystoneV2/Form/Autoplug/AutoplugInfo.vue'
import _ from 'lodash'

@Component({
  components: { AutoplugInfo, AutoplugForm }
})
export default class DataTableAutoplug extends Vue {
  @Prop({ default: (): Autoplugs[] => [] })
  autoplugs: Autoplugs[]

  @Prop()
  company: CompanyModel

  @Prop()
  loading: boolean

  @Prop()
  disableEdit: boolean

  @Prop()
  seatsOfCompany: SiegeSettingModel[]

  @PropSync('isOnEditProp')
  isOnEdit: boolean

  editOpen: number[] = []

  editedItem: Autoplugs = {
    advertisers_to_exclude: [],
    company_id: 0,
    contains: [],
    does_not_contain: [],
    id: 0,
    insertion_date: '',
    io_to_exclude: [],
    is_active: false,
    last_update: '',
    list_advertisers: [],
    list_collaborators: [],
    list_creative_types: [],
    list_dsp: [],
    list_kpis: [],
    list_seats: [],
    min_budget: 0,
    min_fitscore: 0,
    min_media_cost: 0,
    name: '',
    priority: 0,
    start_date: ''
  }

  headers = [
    { text: 'Active', value: 'is_active', sortable: false },
    { text: 'Priority', value: 'priority', sortable: false },
    { text: 'name', value: 'name', sortable: false },
    { text: 'AR ID', value: 'name', sortable: false },
    { text: 'Seats IDS', value: 'name', sortable: false }
  ]

  allRowExpanded = false

  expanded: any[] = []
  toggleEdit (props: any) {
    if (this.isOpenEdit(props.item.id)) {
      this.closeEdit(props)
    } else {
      this.expandEdit(props)
    }
  }

  expandEdit (props: any) {
    this.closeAllRows()
    this.editOpen = []
    this.editOpen.push(props.item.id)
    this.editedItem = _.cloneDeep(props.item)
    props.expand(true)
    this.expanded = [props.item]
  }

  closeEdit (props: any) {
    props.expand(false)
    this.expanded = []
    const ind = this.editOpen.indexOf(props.item.id)
    if (ind !== -1) {
      this.editOpen.splice(ind, 1)
    }
    this.editedItem = null
  }

  isOpenEdit (autoplugId: number) {
    return this.editOpen.includes(autoplugId)
  }

  expandInfo (props: any) {
    if (this.isOpenEdit(props.item.id)) {
      this.closeEdit(props)
    }
    this.expanded = this.expanded.length === 0 ? [props.item] : []
    props.expand(!props.isExpanded)
  }

  remove (autoplugId: number) {
    this.$emit('remove', autoplugId)
  }
  edit (autoplugId: number) {
    this.$emit('edit', autoplugId)
  }

  emitUpdateAutoplug (autoplug: Autoplugs, props: any) {
    let onFail = () => {
      this.expandEdit(props)
      this.editedItem = _.cloneDeep(autoplug)
    }
    this.$emit('update', autoplug, onFail)
    this.closeEdit(props)
  }

  editToggleActive (autoplug: Autoplugs) {
    autoplug.is_active = !autoplug.is_active
    autoplug.start_date = autoplug.start_date ? new Date(autoplug.start_date).toISOString() : null
    this.$emit('update', autoplug)
  }

  updateAllRows (open: boolean) {
    this.expanded = (this.expanded.length === this.autoplugs.length) ? [] : this.autoplugs
  }

  closeAllRows () {
    this.updateAllRows(false)
  }

  openAllRows () {
    this.updateAllRows(true)
  }

  @Watch('editOpen', { deep: true })
  onChangeEditOpen () {
    this.isOnEdit = !!this.editOpen.length
  }

  toggleAllRows () {
    this.updateAllRows(!this.allRowExpanded)
    this.allRowExpanded = !this.allRowExpanded
  }

  get allSeatIds () {
    return this.autoplugs.filter(item => typeof item.list_seats === 'string').reduce((pre, curr, index, array) => {
      pre.push(curr)
      return [new Set(pre)]
    }, [])
  }
}
