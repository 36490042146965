/**
 * Add this mixin for using the open form options in the Keystone V2 store
 * Do not forget to set the entityType
 */
export const openFormOptionMixin = {
  props: {},
  data: function () {
    return {
      /**
       * @type {'metaholding'|'holding'|'company'|'siege'|'member'|null}
       */
      entityType: null
    }
  },
  methods: {
    openFormOptionFunc (openFormOptions) {
      if (openFormOptions.open && openFormOptions.entityType === this.entityType) {
        if (openFormOptions.type === 'new') {
          this.openNew(openFormOptions.parentEntity)
        } else if (openFormOptions.type === 'edit') {
          this.openEdit(openFormOptions.id)
        } else {
          throw new Error('Not implemented openFormOptions type')
        }
        this.$store.commit('resetOpenForm')
      }
    },
    openNew (parentEntity = null) {
      console.warn('Not implemented')
    },
    openEdit (entityId) {
      console.warn('Not implemented')
    }
  },
  computed: {
    openFormOptions () {
      return this.$store.getters.getOpenForm
    }
  },
  watch: {
    openFormOptions: {
      deep: true,
      immediate: false,
      handler: function (openFormOptions) {
        this.openFormOptionFunc(openFormOptions)
      }
    }
  }
}
