export enum UserStatus {
  IS_SETTUPPER = 'isSettupper',
  IS_DEBUGGER = 'isDebugger',
  IS_AM = 'isAm',
  IS_SALES = 'isSales',
  IS_ADMIN_AND_FINANCE = 'isAdminAndFinance'
}

export interface UserStatusState {
  isSettupper: boolean
  isDebugger: boolean
  isAm: boolean
  isSale: boolean
  isAdminAndFinance: boolean
}

export type UserRole = 'am' | 'debugger' | 'sale' | 'admin_and_finance' | 'settupper'
