import Component from 'vue-class-component'
import Vue from 'vue'
import { camelToSnake } from '../../utils/commonUtils'

@Component
export class snakeCaseConverterMixin extends Vue {
  snakeCaseConverter (obj: any) {
    if (!obj) {
      return null
    }
    Object.keys(obj).map(key => {
      if (!key.startsWith('_')) {
        let newKey = camelToSnake(key)
        if (newKey.toString().includes('_')) {
          Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, key))
          delete obj[key]
        }
        if (obj[newKey] !== null && typeof obj[newKey] === 'object') {
          obj[newKey] = this.snakeCaseConverter(obj[newKey])
        }
      }
    })
    return obj
  }
}
