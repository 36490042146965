export function getSearchOrIdSearch (search: string, searchIdString = 'id=') {
  let realSearch
  let isIdSearch = false
  if (typeof search === 'string' && search.trim().startsWith(searchIdString)) {
    realSearch = search.trim().split(searchIdString)[1]
    isIdSearch = true
  } else {
    realSearch = search || null
  }
  return {
    search: realSearch,
    isId: isIdSearch
  }
}

export interface EntityApiPayload {
  limit: number
  offset: number
  search: string
  sortBy?: string
  desc?: boolean
  [idSearch: string]: string | number | boolean | null
}

export function getEntityApiPayload (currentSearch: string, pagination: VDataTablePagination, idSearch: string) {
  let idSearchValue = null
  let search = null
  const res = getSearchOrIdSearch(currentSearch)
  if (res.isId) {
    idSearchValue = res.search
  } else {
    search = res.search
  }

  let payload: EntityApiPayload = {
    limit: pagination.itemsPerPage,
    offset: pagination.itemsPerPage * (pagination.page - 1),
    search: search,
    [idSearch]: idSearchValue
  }

  if (pagination.sortBy) {
    payload.sortBy = pagination.sortBy[0]
    payload.desc = pagination.sortDesc[0]
  }

  return payload
}
