import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import SiegeModel from '@/models/Keystone_v2/SiegeModel'
import MemberModel from '@/models/Keystone_v2/MemberModel'
import { MarkupModuleModel } from '@/models/Keystone_v2/MarkupModuleModel'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'

class SiegeSettingModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.collaboratorGroup = data.collaborator_group
    this.memberSetting = data.keystone_member_setting ? new MemberSettingModel(data.keystone_member_setting) : new MemberSettingModel()
    this.markupModule = data.markup_module
      ? new MarkupModuleModel(data.markup_module)
      : new MarkupModuleModel()
    this.member = data.member ? new MemberModel(data.member) : new MemberModel()
    this.prodAddServingFees = data.prod_add_serving_fees
    this.prodFeeValue = data.prod_fee_value
    this.prodInverseOttoWaterfall = data.prod_inverse_otto_waterfall
    this.prodIsAutoValidation = data.prod_is_auto_validation
    this.prodIsCuicui = data.prod_is_cuicui
    this.prodOverdeliveryCustom = data.prod_overdelivery_custom
    this.prodRespectMinimumLiBudget = data.prod_respect_minimum_li_budget
    this.prodUnderdeliveryCustom = data.prod_underdelivery_custom
    this.prodFeeApplicationMode = data.prod_fee_application_mode
    this.siege = data.siege ? new SiegeModel(data.siege) : new SiegeModel()
    /**
     * @type {{username: string}}
     */
    this.dspLoginInfo = data.dsp_login_info
    this.saveOriginal()
  }
}

export default SiegeSettingModel
