
import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Component
export default class InputTextList extends Vue {
  @PropSync('data')
  dataInput: { [key:string] : string }

  dialog = false
  search = ''
  checkFormat = true

  get getSearchStatus () : boolean {
    return this.search !== ''
  }
  saveData (stringList : string) {
    if (this.validateList(stringList)) {
      this.checkFormat = true
      if (stringList.length !== 0) {
        const serializedData = { 'external_ids': stringList }
        this.dataInput = serializedData
      }
      this.openCloseDialog()
    } else {
      this.checkFormat = false
    }
  }

  validateList (stringList: string) : boolean {
    const whiteSpace = ' '
    const myRegex = new RegExp(`^(${whiteSpace}*[a-zA-Z\\d]+${whiteSpace}*(,+|$))+$`)
    return myRegex.test(stringList)
  }

  openCloseDialog () {
    this.dialog = !this.dialog
  }
}

