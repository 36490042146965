
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Model, Prop } from 'vue-property-decorator'

@Component({})
export default class SimplePrompt extends Vue {
  @Model('change', { type: Boolean })
  readonly dialog!: boolean

  @Prop()
  loading: boolean

  @Prop({ default: 'approve' })
  approve: string

  @Prop({ default: 'red' })
  approveColor: string

  @Prop({ default: 'cancel' })
  cancel: string

  @Prop()
  text: string

  @Emit('approve')
  emitApprove () {}

  @Emit('cancel')
  emitCancel () {}
}
