import MemberModel from '@/models/Keystone_v2/MemberModel'
import SiegeModel from '@/models/Keystone_v2/SiegeModel'

export default class SiegeRuleModel {
  constructor (data) {
    this.siege = data.siege ? new SiegeModel(data) : new SiegeModel()
    this.member = data.member ? new MemberModel(data) : new MemberModel()
    this.allAvertiser = data.all_advertiser
    this.allAgency = data.all_agency
    this.advertiser = data.advertiser
    this.agencyId = data.agency_id
    this.allBut = data.all_but
  }
}
