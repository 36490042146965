
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import HistoryLogsModel from '../../../../models/HistoryLogs/HistoryLogsModel'
import HistoryLogLine from '@/components/TableComponents/Form/FormFragments/HistoryLogLine.vue'

@Component({
  components: {
    HistoryLogLine
  }
})
export default class HistoryHistoryLogsComponent extends Vue {
  @Prop({ required: false, default: null }) entityType: string
  @Prop({ required: false, default: null }) entityId: number
  @Prop({ required: false, default: true }) isReady: boolean

  isLoading: boolean = true

  logs: Array<Array<HistoryLogsModel>> = null
  displayingLogs: Array<HistoryLogsModel> = [] // for pagination
  logsShow: Array<boolean> = null

  arrayLogsNames: any = { new_feature_ref_member: 'feature_name' }

  // PAGINATION
  nbPageMax: number = null
  currentPage: number = 1
  nbByPage: number = 6 // fixed value

  async mounted (): Promise<any> {
    if (this.entityId == null || this.entityType == null) {
      return null
    }
    this.logs = await this.getLogs()
    this.isLoading = false
    return null
  }

  async getLogs (): Promise<Array<Array<HistoryLogsModel>>> {
    let result = await this.$apiCaller.getLogsForEntity(this.entityId, this.entityType)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('getLogs: error when process')
      return null
    } else {
      if (result.data == null || result.data.length === 0) {
        return null
      }
    }

    let paginatedLogs = this.paginateLogs(result.data.map((log: any) => new HistoryLogsModel(log)))
    this.nbPageMax = paginatedLogs.length

    return paginatedLogs
  }

  paginateLogs (logs: Array<HistoryLogsModel>): Array<Array<HistoryLogsModel>> {
    const chunks: Array<Array<HistoryLogsModel>> = []
    logs = [].concat(...logs)

    while (logs.length) {
      chunks.push(logs.splice(0, this.nbByPage))
    }
    return chunks
  }

  get getDialogHeader () {
    return (`Logs history for ${this.entityType.replace('_', ' ')}`).toUpperCase()
  }

  getUsername (userMail: string) {
    if (userMail == null) { return null }
    return userMail.substring(0, userMail.indexOf('@')).charAt(0).toUpperCase() + userMail.substring(0, userMail.indexOf('@')).substring(1)
  }

  getModificationDate (date: any) {
    let dateObj = new Date(date)
    let year = dateObj.getFullYear()
    let month = (dateObj.getMonth() + 1).toString().length === 2 ? dateObj.getMonth() + 1 : '0' + (dateObj.getMonth() + 1)
    let day = dateObj.getDate().toString().length === 2 ? dateObj.getDate() : '0' + dateObj.getDate()
    let hours = dateObj.getHours().toString().length === 2 ? dateObj.getHours() : '0' + dateObj.getHours()
    let minutes = dateObj.getMinutes().toString().length === 2 ? dateObj.getMinutes() : '0' + dateObj.getMinutes()
    let seconds = dateObj.getSeconds().toString().length === 2 ? dateObj.getSeconds() : '0' + dateObj.getSeconds()
    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds} UTC`
  }

  formatTitle (str: string) {
    // Special case for Keystone Settings, New Feature Reference and ID
    if (str.toLowerCase() === 'keystone_settings_siege') {
      return 'Keystone Seat Properties'
    } else if (str.toLowerCase() === 'keystone_settings_member') {
      return 'Keystone Member Properties'
    } else if (str.toLowerCase() === 'new_feature_ref_member') {
      return 'New Feature References'
    } else if (str.toLowerCase() === 'id') {
      return 'ID'
    }
    return str.toLowerCase().split('_').map((word: string) => word.charAt(0).toUpperCase() + word.toLowerCase().substring(1)).join(' ')
  }

  formatValue (value: string | number | boolean): string | number {
    if (typeof value === 'boolean') {
      return value.toString().charAt(0).toUpperCase() + value.toString().toLowerCase().substring(1)
    }
    return value
  }

  switchLogCard (displayedLogs: Array<HistoryLogsModel>, index: number, value: boolean = null) {
    if (value == null) {
      value = !displayedLogs[index].show
    }
    Vue.set(displayedLogs[index], 'show', value)
  }

  expandOrCloseAll (action: string = 'close', displayedLogs: Array<HistoryLogsModel> = null) {
    const value = action === 'expand'
    if (displayedLogs == null) {
      displayedLogs = this.getCurrentDisplayedLogs
    }
    for (let index = 0; index < displayedLogs.length; index++) {
      this.switchLogCard(displayedLogs, index, value)
    }
  }

  resetExpand () {
    this.logs.forEach((logPage: Array<HistoryLogsModel>) => {
      this.expandOrCloseAll('close', logPage)
    })
  }

  get getPageOffset () {
    return ((this.currentPage - 1) * this.nbByPage) + 1
  }

  get getCurrentDisplayedLogs () {
    return this.logs[this.currentPage - 1]
  }
}
