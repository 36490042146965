
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Model, Prop, Watch } from 'vue-property-decorator'
import { Autoplugs } from '../../../../../types/autoplugs_type'
import * as keystoneV2Utils from '../../../../../utils/keystoneV2Utils'
import SelectWithChip from '@/components/KeystoneV2/Form/Autoplug/SelectWithChip.vue'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import { rulesMixin } from '@/mixins/rulesMixin'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import { range } from 'lodash'
import SelectMultiple from '@/components/KeystoneV2/Form/Autoplug/SelectMultiple.vue'
import LineAutoplugForm from '@/components/KeystoneV2/Form/Autoplug/LineAutoplugForm.vue'
import AdvertiserModel from '@/models/Keystone_v2/AdvertiserModel'
import { getKpiItems } from '@/components/KeystoneV2/Form/Autoplug/kpiByDspAutoplugs'
import DatePicker from '@/components/TableComponents/Form/FormFragments/DatePicker.vue'
import { OverviewApiArgs } from '../../../../../types/overview_types'

export type AutoplugInputType = 'select' | 'text-field' | 'combobox' | 'autocomplete' | 'date'

@Component({
  components: { DatePicker, LineAutoplugForm, SelectMultiple, SelectWithChip },
  mixins: [rulesMixin]
})
export default class AutoplugForm extends Vue {
  @Model('change')
  readonly autoplug!: Autoplugs

  @Prop({ default: (): Autoplugs[] => [] })
  allAutoplugs: Autoplugs[]

  @Prop({ default: false })
  loading: boolean

  @Prop()
  company: CompanyModel

  @Prop()
  seatsOfCompany: SiegeSettingModel[]

  @Prop()
  isEdit: boolean

  dspItems: TextValue[] = keystoneV2Utils.getDspSelectItems(['facebook', 'beeswax'])

  formIsValidated = false
  loadingAdvertiser = false
  loadingIo = false

  inputTypeWithItems: AutoplugInputType[] = ['select', 'autocomplete', 'date']

  /**
   * optional criteria
   */
  readonly allCriteria: TextValueType<KeysOf<Autoplugs> | ''>[] = [
    { text: '--Other Criteria--', value: '' },
    { text: 'KPI', value: 'list_kpis' },
    { text: 'IO name contains', value: 'contains' },
    { text: 'IO name does not contains', value: 'does_not_contain' },
    { text: 'Advertiser to exclude', value: 'advertisers_to_exclude' },
    { text: 'Advertiser', value: 'list_advertisers' },
    { text: 'Io to exclude', value: 'io_to_exclude' },
    { text: 'Minimum fit score', value: 'min_fitscore' },
    { text: 'Media cost', value: 'min_media_cost' }
    // { text: 'Start date', value: 'start_date' } // Not enabled for the moment
  ]

  criteriaFormConfig: Record<WritableKeysOf<Autoplugs>, {
    inputType: AutoplugInputType,
    items?: TextValue[] | TextValueNumber[],
    infoMessage?: string,
    loading?: boolean,
    search?: string,
    itemDisabled?: (item: TextValue) => boolean,
    prefix?: string
  }> = {
    advertisers_to_exclude: {
      inputType: 'autocomplete',
      infoMessage: 'To exclude all IOs from a set of advertisers',
      items: [],
      search: '',
      itemDisabled: (item: TextValue) => {
        return this.autoplug.list_advertisers.includes(Number(item.value))
      },
      loading: false
    },
    company_id: { inputType: undefined },
    contains: { inputType: 'combobox', infoMessage: 'To include only IOs named a certain way' },
    does_not_contain: { inputType: 'combobox', infoMessage: 'To exclude all IOs named a certain way' },
    id: { inputType: undefined },
    insertion_date: { inputType: undefined },
    io_to_exclude: {
      inputType: 'autocomplete',
      infoMessage: 'To exclude some IOs from the alerting',
      items: [],
      search: '',
      loading: false
    },
    is_active: { inputType: undefined },
    last_update: { inputType: undefined },
    list_advertisers: {
      inputType: 'autocomplete',
      infoMessage: 'To include only IOs from a set of advertisers',
      items: [],
      search: '',
      itemDisabled: (item: TextValue) => {
        return this.autoplug && Array.isArray(this.autoplug.advertisers_to_exclude) && this.autoplug.advertisers_to_exclude.includes(Number(item.value))
      },
      loading: false
    },
    list_collaborators: { inputType: undefined, infoMessage: 'People to receive the alerts' },
    list_creative_types: { inputType: undefined, infoMessage: 'To include only IOs with defined creative types' },
    list_dsp: { inputType: undefined, infoMessage: 'To include only IOs from a set of DSPs' },
    list_kpis: {
      inputType: 'autocomplete',
      items: getKpiItems(),
      infoMessage: 'To include only IOs with specific KPI goal',
      itemDisabled: (item: TextValue) => {
        return false
        /*        let enableDsp = this.autoplug.list_dsp
        let kpiByDsp = getKpiByDsp(enableDsp)
        return !kpiByDsp.includes(item.value) */
      }
    },
    min_budget: { inputType: undefined, infoMessage: 'To include only IOs with a high enough flight budget' },
    min_fitscore: { inputType: 'text-field', infoMessage: 'To include only IOs with good enough fit scores' },
    min_media_cost: {
      inputType: 'text-field',
      infoMessage: 'To include only IOs spending enough daily',
      prefix: '$'
    },
    name: { inputType: undefined, infoMessage: 'Choose an exhaustive name' },
    priority: { inputType: undefined, infoMessage: 'To assign a priority to this rule' },
    list_seats: {
      inputType: 'select',
      infoMessage: 'To include only IOs from a set of seats'
    },
    start_date: { inputType: 'date', infoMessage: 'To include only upcoming IOs' }
  }

  criteriaToDisplay: TextValueType<KeysOf<Autoplugs>>[] = []

  readonly baseValueOtherCriteria: TextValueType<KeysOf<Autoplugs> | ''> = { text: '--Other Criteria--', value: '' }
  otherCriteria: TextValueType<KeysOf<Autoplugs> | ''> | null = this.baseValueOtherCriteria

  mounted () {
    this.setCriteriaToDisplay()
    this.setCurrentAdvertisers()
    this.setCurrentOverviews()
  }

  getInfoMessage (key: WritableKeysOf<Autoplugs>) {
    return this.criteriaFormConfig[key].infoMessage
  }

  get siegeItems ():TextValue[] {
    return this.seatsOfCompany.map((item: SiegeSettingModel) => {
      return {
        text: `(${item.member.externalId}) ${item.siege.name}`,
        value: item.siege.id
      }
    })
  }

  get collaboratorItems ():TextValue[] {
    let collaboratorItem: TextValue[] = []
    let alreadyPutEmail: string[] = []
    let collaborators = this.$store.getters.getCollaborators
    for (let i in collaborators) {
      let item = collaborators[i]
      if (!alreadyPutEmail.includes(item.email)) {
        alreadyPutEmail.push(item.email)
        collaboratorItem.push({
          text: item.email,
          value: item.id
        })
      }
    }
    return collaboratorItem.sort((a, b) => {
      return a.text.localeCompare(b.text)
    })
  }

  async getAdvertisersAutocomplete (siegeIds?: number[], search?: string, idList?: number[]) {
    this.loadingAdvertiser = true
    let advertisers: AdvertiserModel[] = []
    // eslint-disable-next-line camelcase
    let searchData: {search?: string, siege_id?: string, id_list?: string} = {}
    if (siegeIds) {
      searchData.siege_id = siegeIds.join(',')
    }

    if (search) {
      searchData.search = search
    }

    if (idList) {
      searchData.id_list = idList.join(',')
    }
    const response = await this.$apiCaller.getAdvertisers(searchData)

    if (this.$apiCaller.isResponseError(response, true)) {
      console.warn('Error when getting advertiser autocomplete autoplugs')
    } else {
      response.data.forEach((item: {}) => {
        advertisers.push(new AdvertiserModel(item))
      })
    }
    this.loadingAdvertiser = false
    return advertisers
  }

  async getIoAutocomplete (search?: string, idList?: number[], advertiserList?: number[], siegeList?: number[]): Promise<OverviewApiArgs[]> {
    this.loadingIo = true
    let overviews: OverviewApiArgs[] = []
    const response = await this.$apiCaller.getOverviewsWithSearch({
      search: search,
      idList: idList,
      companyId: this.company.id,
      advertiserList: advertiserList,
      siegeList: siegeList
    })

    if (this.$apiCaller.isResponseError(response, true)) {
      console.warn('Error when getting overviews autocomplete autoplugs')
    } else {
      if (response.data.length) {
        overviews = response.data
      }
    }
    this.loadingIo = false
    return overviews
  }

  removeCriteriaToDisplay (index: number) {
    const criteria = this.criteriaToDisplay[index]
    if (criteria.value in this.autoplug && this.autoplug[criteria.value]) {
      this.autoplug[criteria.value as ArrayKeys<Autoplugs>] = []
    }
    this.criteriaToDisplay.splice(index, 1)
  }

  setCriteriaToDisplay () {
    for (let criteria of this.allCriteria) {
      if (criteria.value !== '' &&
        ((Array.isArray(this.autoplug[criteria.value]) && (this.autoplug[criteria.value] as Array<any>).length) ||
          (!Array.isArray(this.autoplug[criteria.value]) && this.autoplug[criteria.value])
        )) {
        this.criteriaToDisplay.push(criteria as TextValueType<KeysOf<Autoplugs>>)
      }
    }
  }

  isAutoplugWithItems (inputType: AutoplugInputType) {
    return this.inputTypeWithItems.includes(inputType)
  }

  async setCurrentPriority () {
    if (!this.isEdit) {
      this.autoplug.priority = this.itemsPriority[this.itemsPriority.length - 1]
    }
  }

  async setCurrentAdvertisers () {
    let keyToCheckAdvertiserAutoplug: WritableKeysOf<Autoplugs>[] = ['list_advertisers', 'advertisers_to_exclude']

    for (let i in keyToCheckAdvertiserAutoplug) {
      const key = keyToCheckAdvertiserAutoplug[i]
      if (Array.isArray(this.autoplug[key])) {
        let idList = this.autoplug[key] as number[]
        this.criteriaFormConfig[key].loading = true
        const items: AdvertiserModel[] = await this.getAdvertisersAutocomplete(null, null, idList)
        this.criteriaFormConfig[key].items = items.map(this.advertiserToTextValue)
        this.criteriaFormConfig[key].loading = false
      }
    }
  }

  async setCurrentOverviews () {
    if (Array.isArray(this.autoplug.io_to_exclude)) {
      let idList = this.autoplug.io_to_exclude
      this.criteriaFormConfig.io_to_exclude.loading = true
      const items: OverviewApiArgs[] = await this.getIoAutocomplete(null, idList)
      this.criteriaFormConfig.io_to_exclude.items = items.map(this.overviewToTextValue)
      this.criteriaFormConfig.io_to_exclude.loading = false
    }
  }

  errorMessageRank () {
    let errors = []
    let maxRank = this.allAutoplugs.length
    maxRank = this.isEdit ? maxRank : maxRank + 1
    if (this.autoplug.priority > maxRank) {
      errors.push('Priority can not be more than the number of autoplugs')
    }
    return errors
  }

  errorMessageItem (key: KeysOf<Autoplugs>) {
    let error: string[] = []
    switch (key) {
      case 'min_fitscore':
        if (this.autoplug.min_fitscore && isNaN(this.autoplug.min_fitscore)) {
          error.push('Min fitscore must be a numeric value')
        }

        if (this.autoplug.min_fitscore && this.autoplug.min_fitscore > 100) {
          error.push('Max fitscore is 100 !')
        }
        break
      default:
        error = []
    }
    return error
  }

  itemDisabledOtherCriteria (item: TextValueType<KeysOf<Autoplugs>>) {
    const displayedkeys: KeysOf<Autoplugs>[] = this.criteriaToDisplay.map((criteria) => criteria.value)
    return displayedkeys.includes(item.value)
  }

  get itemsPriority () {
    let items: number[] = []
    // +1 for array length, +1 for allowing one priority higher than the higher priority if not edit
    let toAdd = this.isEdit ? 1 : 2
    const maxPriority = this.maxPriority + toAdd
    if (!this.$commonUtils.isNumeric(maxPriority) || !Number.isFinite(maxPriority)) {
      return [1]
    }
    const _range = range(1, maxPriority)
    for (let i in _range) {
      items.push(_range[i])
    }
    return items
  }

  get maxPriority () {
    return Math.max(...this.allAutoplugs.map(autoplug => autoplug.priority))
  }

  @Watch('otherCriteria')
  onChangeOtherCriteria (otherCriteria: TextValueType<KeysOf<Autoplugs> | ''> | null) {
    if (!otherCriteria || otherCriteria.value === '') {
      return
    }
    this.criteriaToDisplay.push(otherCriteria as TextValueType<KeysOf<Autoplugs>>)
    this.$nextTick(() => {
      this.otherCriteria = this.baseValueOtherCriteria
    })
  }

  @Emit('validate')
  validate () {
    if (this.autoplug.start_date) {
      this.autoplug.start_date = (new Date(this.autoplug.start_date)).toISOString()
    }
  }

  @Emit('cancel')
  cancel () {}

  advertiserToTextValue (ad: AdvertiserModel): TextValueNumber {
    return {
      text: `(${ad.externalId}) ${ad.name}`,
      value: ad.id
    }
  }

  overviewToTextValue (io: OverviewApiArgs): TextValueNumber {
    return {
      text: `(${io.external_id}) ${io.name}`,
      value: io.id
    }
  }

  async onChangeAdvertiser (search: string, key: 'advertisers_to_exclude' | 'list_advertisers') {
    if (!search) {
      return
    }
    this.criteriaFormConfig[key].loading = true
    const siegeIds = this.seatsOfCompany.map(item => item.siege.id)
    const items: AdvertiserModel[] = await this.getAdvertisersAutocomplete(siegeIds, search)

    if (!items.length) {
      this.criteriaFormConfig[key].loading = false
      return
    }
    let currentAd = this.autoplug[key]
    let currentItems = (this.criteriaFormConfig[key].items as TextValueNumber[]).filter(ad => currentAd.includes(ad.value))
    this.criteriaFormConfig[key].items = [...new Set([...items.map(this.advertiserToTextValue), ...currentItems])]
    this.criteriaFormConfig[key].loading = false
  }

  async onChangeOverviews (search: string) {
    if (!search) {
      return
    }
    this.criteriaFormConfig.io_to_exclude.loading = true
    const adList = Array.isArray(this.autoplug.list_advertisers) && this.autoplug.list_advertisers.length ? this.autoplug.list_advertisers : null
    const siegeList = Array.isArray(this.autoplug.list_seats) && this.autoplug.list_seats.length ? this.autoplug.list_seats : null
    const items: OverviewApiArgs[] = await this.getIoAutocomplete(search, null, adList, siegeList)

    if (!items.length) {
      this.criteriaFormConfig.io_to_exclude.loading = false
      return
    }
    let currentOverviews = this.autoplug.io_to_exclude
    let currentItems = (this.criteriaFormConfig.io_to_exclude.items as TextValueNumber[]).filter(io => {
      return currentOverviews.includes(io.value)
    })
    this.criteriaFormConfig.io_to_exclude.items = [...new Set([...items.map(this.overviewToTextValue), ...currentItems])]
    this.criteriaFormConfig.io_to_exclude.loading = false
  }

  @Watch('allAutoplugs')
  onChangeAllAutoplugs () {
    this.setCurrentPriority()
  }

  @Watch('criteriaFormConfig.advertisers_to_exclude.search')
  async onChangeSearchAdvertiserToExclude (search: string) {
    await this.onChangeAdvertiser(search, 'advertisers_to_exclude')
  }

  @Watch('criteriaFormConfig.list_advertisers.search')
  async onChangeSearchListAdvertisers (search: string) {
    await this.onChangeAdvertiser(search, 'list_advertisers')
  }

  @Watch('criteriaFormConfig.io_to_exclude.search')
  async onChangeSearchListOverviews (search: string) {
    await this.onChangeOverviews(search)
  }
}
