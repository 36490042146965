import { render, staticRenderFns } from "./InputFile.vue?vue&type=template&id=455ff274&scoped=true"
import script from "./InputFile.vue?vue&type=script&lang=ts"
export * from "./InputFile.vue?vue&type=script&lang=ts"
import style0 from "./InputFile.vue?vue&type=style&index=0&id=455ff274&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "455ff274",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VTooltip})
