<template>
  <v-row wrap>
    <v-col cols="12" v-if="[$DBM].indexOf(currentDsp) !== -1">
      <v-row row>
        <v-col cols="8" :class="classLabel">
          DT configuration
        </v-col>
        <v-col cols="4" :class="classValue">
          <span :style="`color : ${dspSettings !== null && dspSettings.dtConfiguration && dspSettings.dtConfiguration.status !== 'None' ? 'green' : 'black'}`">
            {{dspSettings !== null && dspSettings.dtConfiguration ? dspSettings.dtConfiguration.status : 'None'}}
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters wrap v-if="dspSettings !== null && dspSettings.dtConfiguration && dspSettings.dtConfiguration.status === 'Active'">
        <v-col cols="6" offset="4" class="bucket-info">
          bucket type : {{dspSettings.dtConfiguration.bucketType}}
        </v-col>
        <v-col cols="6" offset="4" class="bucket-info">
          bucket level : {{dspSettings.dtConfiguration.bucketLevel}}
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row v-if="[$DBM].indexOf(currentDsp) !== -1">
        <v-col cols="8" :class="classLabel">
          vCPM/CPCV compatibility
        </v-col>
        <v-col cols="4" :class="classValue">
          <span :style="`color : ${dspSettings !== null && dspSettings.dtConfiguration && dspSettings.vCPMcPCVcompatibility !== 'None' ? 'green' : 'black'}`">
            {{dspSettings !== null ? dspSettings.vCPMcPCVcompatibility : 'None'}}
          </span>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      v-if="[$MEDIAMATH, $BEESWAX, $THETRADEDESK].indexOf(currentDsp) !== -1"
    >
      <v-row row>
        <v-col cols="8" :class="classLabel">
          LLD configuration
        </v-col>
        <v-col cols="4" :class="classValue">
          <span :style="`color : ${dspSettings !== null && dspSettings.lldConfiguration && dspSettings.lldConfiguration.status !== 'None' ? 'green' : 'black'}`">
            {{dspSettings !== null && dspSettings.lldConfiguration ? dspSettings.lldConfiguration.status : 'None'}}
          </span>
        </v-col>
      </v-row>
      <v-row wrap v-if="dspSettings !== null && dspSettings.lldConfiguration && dspSettings.lldConfiguration.status === 'Active'">
        <v-col cols="6" offset-xs="4" class="bucket-info">
          bucket level : {{dspSettings.lldConfiguration.bucketLevel}}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { DSPSettingsModel } from '@/models/Keystone/DSPSettingsModel'
import { classLabelValueMixin } from '@/mixins/classLabelValueMixin'

export default {
  name: 'DspSettingsInfo',
  mixins: [classLabelValueMixin],
  props: {
    dspSettings: {
      type: DSPSettingsModel,
      default: () => {
        return null
      }
    },
    currentDsp: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.bucket-info {
  font-size: 12px;
  font-style: italic;
  border-left: 2px solid cornflowerblue;
  text-align: left;
  border-bottom: 2px solid cornflowerblue;
  padding-left: 2em !important;
}
</style>
