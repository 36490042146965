
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component({})
export default class SelectWithChip extends Vue {
  @Prop()
  list: string[]

  @Prop()
  label: string

  @Prop()
  items: TextValue[]

  @Prop({ default: false })
  valueInItem: boolean

  @Prop({ default: false })
  required: boolean

  selected = ''

  @Emit('remove')
  emitRemove (toRemove: string) {}

  @Emit('add')
  emitAdd (toAdd: string) {
    this.$nextTick(() => {
      this.selected = ''
    })
  }

  getValue (value: any) {
    if (!this.valueInItem) {
      return value
    }
    return this.items.filter((item: TextValue) => {
      return item.value === value
    })[0].text
  }

  itemDisabled (item: TextValue) {
    return this.list && this.list.includes(item.value)
  }

  requiredErrorMessage (): string[] {
    if (!this.required) {
      return []
    }
    return this.list.length ? [] : ['Required field']
  }
}
