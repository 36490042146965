
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import { Autoplugs } from '../../../../../types/autoplugs_type'
import AutoplugForm from '@/components/KeystoneV2/Form/Autoplug/AutoplugForm.vue'
import DataTableAutoplug from '@/components/KeystoneV2/Form/Autoplug/DataTableAutoplug.vue'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import SimplePrompt from '@/components/Common/SimplePrompt.vue'
import { dateToDatePickerDate } from '../../../../../utils/DatePickerHelper/datePickerHelper'
import { usersRoleMixin } from '@/mixins/usersRoleMixin'

@Component({
  components: { SimplePrompt, AutoplugForm, DataTableAutoplug }
})
export default class AutoplugTab extends mixins(usersRoleMixin) {
  @Prop()
  entity: CompanyModel

  @Prop()
  seatsOfCompany: SiegeSettingModel[]

  @PropSync('autoplugsUpdatedAndNotValidatedProp')
  autoplugsUpdatedAndNotValidated: boolean

  errorGet = false
  autoplugs: Autoplugs[] = []
  loading = false
  showForm = false
  newAutoplug: Autoplugs = this.getDefaultAutoplug()
  processingNew = false
  successNew = false
  errorNew = false

  processingUpdate = false
  successUpdate = false
  errorUpdate = false

  processingDelete = false
  successDelete = false
  errorDelete = false

  dialogCancel = false
  toDeleteId: number = null
  msTimeOutAlert: number = 5000

  isOnEdit: boolean = false

  readonly editRole = 'api.autoplug:put'
  readonly createRole = 'api.company.autoplugs:post'
  readonly readRole = 'api.company.autoplugs:get'

  mounted () {
    this.getAutoplugs()
  }

  async getAutoplugs () {
    if (!this.entity.id) {
      console.warn('No company_id, do not call autoplugs')
      return
    }
    this.loading = true
    const response = await this.$apiCaller.getAutoplugsOfCompany(this.entity.id)
    this.errorGet = false
    if (this.$apiCaller.isResponseError(response)) {
      this.errorGet = true
      this.$store.commit('setErrorMessageWithResponse', response)
    } else {
      this.autoplugs = this.convertDateAutoplugs(response.data)
    }
    this.$emit('send-autoplug-alerts-to-company-form', this.autoplugs)
    this.loading = false
  }

  convertDateAutoplugs (data: Autoplugs[]) {
    return data.map((item) => {
      return this.convertDateAutoplug(item)
    })
  }

  convertDateAutoplug (autoplug: Autoplugs) {
    autoplug.start_date = dateToDatePickerDate(new Date(autoplug.start_date))
    return autoplug
  }

  toggleAutoplugForm () {
    this.closeExpandAll()
    this.showForm = !this.showForm
  }

  getIsExpanded () {
    const dataTableAutoplug = this.$refs.dataTableAutoplug as Vue & {allRowExpanded: boolean}
    return dataTableAutoplug && dataTableAutoplug.allRowExpanded
  }

  expandAll () {
    const dataTableAutoplug = this.$refs.dataTableAutoplug as Vue & {toggleAllRows: Function}
    if (dataTableAutoplug) {
      dataTableAutoplug.toggleAllRows()
    }
  }

  closeExpandAll () {
    const dataTableAutoplug = this.$refs.dataTableAutoplug as Vue & {closeAllRows: Function}
    if (dataTableAutoplug) {
      dataTableAutoplug.closeAllRows()
    }
  }

  async saveNewAutoplug () {
    this.processingNew = true
    this.errorNew = false
    this.successNew = false
    const result = await this.$apiCaller.postAutoplugs(this.newAutoplug)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when creating autoplug')
      this.errorNew = true
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      this.successNew = true
      this.showForm = false
      this.newAutoplug = this.getDefaultAutoplug()
    }

    this.processingNew = false
    await this.getAutoplugs()
  }

  /**
   * call api for update autoplugs
   * @param autoplug
   * @param onFail if not null, will call this function if the api return a error response
   */
  async updateAutoplug (autoplug: Autoplugs, onFail: Function = null) {
    if (!autoplug) {
      return
    }
    const aut: Autoplugs = {
      ...autoplug,
      company_id: this.entity.id
    }
    this.successUpdate = false
    this.processingUpdate = true
    this.errorUpdate = false
    const result = await this.$apiCaller.putAutoplug(aut)
    if (this.$apiCaller.isResponseError(result)) {
      this.errorUpdate = true
      this.$store.commit('setErrorMessageWithResponse', result)
      console.warn('Error when updating autoplugs')
      if (typeof onFail === 'function') {
        onFail()
      }
    } else {
      this.successUpdate = true
    }
    this.processingUpdate = false
    await this.getAutoplugs()
  }

  async continueProcessDelete (autoplugId: number) {
    await this.deleteAutoplug(autoplugId)
    this.dialogCancel = false
    this.toDeleteId = null
  }

  async deleteAutoplug (autoplugId: number) {
    if (!autoplugId) {
      return
    }
    this.successDelete = false
    this.processingDelete = true
    this.errorDelete = false
    const result = await this.$apiCaller.deleteAutoplug(autoplugId)
    if (this.$apiCaller.isResponseError(result)) {
      this.errorDelete = true
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      this.successDelete = true
    }
    this.processingDelete = false
    await this.getAutoplugs()
  }

  processDelete (autoplugId: number) {
    this.toDeleteId = autoplugId
    this.dialogCancel = true
  }

  cancelDelete () {
    this.dialogCancel = false
    this.toDeleteId = null
  }

  get textDialogCancel (): string {
    return `Are you sur you want to delete the autoplug id ${this.toDeleteId} ?`
  }

  getDefaultAutoplug (): Autoplugs {
    return {
      advertisers_to_exclude: [],
      company_id: this.entity.id,
      contains: [],
      does_not_contain: [],
      id: 0,
      insertion_date: '',
      io_to_exclude: [],
      is_active: true,
      last_update: '',
      list_creative_types: [],
      min_budget: null,
      min_fitscore: null,
      min_media_cost: null,
      name: '',
      priority: 1,
      list_dsp: [],
      list_seats: [],
      list_advertisers: [],
      list_kpis: [],
      list_collaborators: [],
      start_date: null
    }
  }

  onEventFunc<T> (t: T, prop: KeysOf<T>) {
    if (t[prop]) {
      setTimeout(() => { (t[prop] as unknown as boolean) = false }, this.msTimeOutAlert)
    }
  }

  get autoplugsByRank () {
    return this.autoplugs.sort((a: Autoplugs, b: Autoplugs) => {
      return a.priority - b.priority
    })
  }

  setAutoplugsupdatedAndNotValidated () {
    this.autoplugsUpdatedAndNotValidated = this.isOnEdit || this.showForm
  }

  @Watch('showForm')
  onShowForm () {
    this.setAutoplugsupdatedAndNotValidated()
  }

  @Watch('isOnEdit')
  onChangeIsOnEdit () {
    this.setAutoplugsupdatedAndNotValidated()
  }

  @Watch('successNew')
  onSuccessNew () {
    this.onEventFunc(this, 'successNew')
  }

  @Watch('successUpdate')
  onSuccessUpdate () {
    this.onEventFunc(this, 'successUpdate')
  }

  @Watch('successDelete')
  onSuccessDelete () {
    this.onEventFunc(this, 'successDelete')
  }

  @Watch('errorNew')
  onErrorNew () {
    this.onEventFunc(this, 'errorNew')
  }

  @Watch('errorUpdate')
  onErrorUpdate () {
    this.onEventFunc(this, 'errorUpdate')
  }

  @Watch('errorDelete')
  onErrorDelete () {
    this.onEventFunc(this, 'errorDelete')
  }
}
