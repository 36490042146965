<template>
  <v-row row>
    <v-col cols="4" :class="classLabel">
      DSP login
    </v-col>
    <v-col cols="8" class="dsp-login-data" :class="classValue">

      <v-row wrap>
        <v-col>
          <v-tooltip bottom >
            <template v-slot:activator="{ on }">
              <span v-on="on">{{$commonUtils.sliceIfTooLength(dspLogin, maxLength)}}</span>
            </template>
            <span>
              <span>{{dspLogin}}</span>
            </span>
          </v-tooltip>
        </v-col>
        <v-col>
          <CopyClipboardComponent
            :toCopy="dspLogin"
            :tooltipText="'Copy dsp login to clipboard.'"
            :btnSmall="true"
            :elevation="false"
            color="transparent"
          >

          </CopyClipboardComponent>
        </v-col>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import { classLabelValueMixin } from '@/mixins/classLabelValueMixin'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent'

export default {
  name: 'DspLoginInfo',
  components: { CopyClipboardComponent },
  mixins: [classLabelValueMixin],
  props: {
    dspLogin: {
      type: String
    },
    maxLength: {
      type: Number,
      default: 24
    }
  },
  data: function () {
    return {
    }
  }
}
</script>

<style scoped>
  .dsp-login-data {
    text-align: center;
  }
</style>
