import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import HoldingModel from '@/models/Keystone_v2/HoldingModel'
import MetaHoldingModel from '@/models/Keystone_v2/MetaHoldingModel'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'

export const entityFormMixin = {
  props: {
    value: {
      type: Boolean
    },
    isEditForm: {
      type: Boolean,
      default: false
    },
    entityId: {
      type: [Number, null],
      default: null
    },
    defaultEntity: {
      type: BaseKeystoneEntity,
      default: null
    }
  },
  data: function () {
    return {
      /**
       * @type {'metaholding'|'holding'|'company'|'siege'|'member'}
       */
      entityType: null,
      dialog: false,
      /**
       * @type {MetaHoldingModel|MemberSettingModel|HoldingModel|CompanyModel|SiegeSettingModel}
       */
      currentEntity: this.createNewEntity()
    }
  },
  mounted () {
    this.dialog = this.value
  },
  methods: {
    /**
     * @return {MetaHoldingModel|MemberSettingModel|HoldingModel|CompanyModel|SiegeSettingModel}
     */
    createNewEntity () {
      const entityType = this.entityType

      if (entityType === 'metaholding') {
        return new MetaHoldingModel()
      } else if (entityType === 'holding') {
        return new HoldingModel()
      } else if (entityType === 'company') {
        return new CompanyModel()
      } else if (entityType === 'siege') {
        return new SiegeSettingModel()
      } else if (entityType === 'member') {
        return new MemberSettingModel()
      } else {
        console.warn('Cant create new entity')
        return new HoldingModel()
      }
    },
    getCurrentEntity () {
      console.warn('Not implemented')
    },
    getChildOfCurrentEntity () {
      console.warn('Not implemented')
    },
    /**
     * On edit : call the api for get the current entity
     * On new : If a defaultEntity has been set, use her as current entity. Otherwise, create a new Entity with no default value
     * @return {Promise<void>}
     */
    async onOpenEntityForm () {
      if (this.isEditForm) { // EDIT
        await this.getCurrentEntity(this.entityId)
      } else { // NEW
        if (this.defaultEntity) {
          this.currentEntity = this.defaultEntity
        } else {
          this.currentEntity = this.createNewEntity()
        }
      }
      this.currentEntity.saveOriginal()
    },
    getEntitiesNameToTest (entitiesToTest) {
      if (this.isEditForm) {
        return entitiesToTest
          .filter(item => item.id !== this.currentEntity.id)
          .map(item => item.name)
      }
      return entitiesToTest.map(item => item.name)
    }
  },
  computed: {},
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    async dialog (val) {
      this.$emit('input', val)
      if (val) {
        await this.onOpenEntityForm()
      }
    },
    'currentEntity.id': {
      deep: true,
      immediate: false,
      handler: function (currentEntityId) {
        if (this.isEditForm && this.currentEntity && currentEntityId) {
          this.getChildOfCurrentEntity()
        }
      }
    }
  }
}
