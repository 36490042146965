
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class DisableFieldsAlertMessage extends Vue {
  @Prop()
  condition: boolean
  @Prop()
  statusNeededText: string
}

