<template>
  <v-row wrap>
    <v-col cols="12" v-for="(item, index) in dspSettingsForm" v-bind:key="index">
      <v-checkbox
        :label="item.label"
        v-model="item.value"
        color="success"
        hide-details
        :disabled="true"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { DSPSettingsModel } from '@/models/Keystone/DSPSettingsModel'

export default {
  name: 'DspSettingsForm',
  props: {
    dspSettings: {
      type: DSPSettingsModel,
      default: () => {
        return null
      }
    },
    currentDsp: {
      type: String,
      default: null
    }
  },
  computed: {
    dspSettingsForm () {
      if (this.dspSettings === null || this.dspSettings === undefined) {
        return []
      }
      if (this.currentDsp === this.$APPNEXUS) {
        return [
          { label: 'Is Full Hashed', value: this.dspSettings.isFullHashed },
          { label: 'Is GDPR', value: this.dspSettings.isGdpr }
        ]
      } else if (this.currentDsp === this.$DBM) {
        return [
          { label: 'Dt activated', value: this.dspSettings.dtActivated },
          { label: 'Dt level', value: this.dspSettings.dtLevel },
          { label: 'Reports Only', value: this.dspSettings.reportsOnly }
        ]
      } else {
        return [
          { label: 'Data level', value: this.dspSettings.dataLevel }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
