<template>
  <v-row row>
    <v-col cols="8" :class="classLabel">
      Set-Up Status
    </v-col>
    <v-col cols="4" :class="classValue">
      <span :style="`color : ${dspSettings !== null && dspSettings.isActive ? 'green' : 'orange'}`">
        {{dspSettings !== null && dspSettings.isActive ? 'Active' : 'Inactive'}}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { DSPSettingsModel } from '@/models/Keystone/DSPSettingsModel'
import { classLabelValueMixin } from '@/mixins/classLabelValueMixin'

export default {
  name: 'SettingsIsActiveInfo',
  mixins: [classLabelValueMixin],
  props: {
    dspSettings: {
      type: DSPSettingsModel,
      default: () => {
        return null
      }
    }
  }
}
</script>

<style scoped>
</style>
