<template>
  <v-row no-gutters row>
    <v-col cols="2">
      <v-btn
        @click="toggleValue"
        small
        fab
        class="active-btn"
        :disabled="disabled"
      >
        <v-icon :color="content ? 'green' : 'gray'">power_settings_new</v-icon>
      </v-btn>
      <!-- for error message system -->
      <v-text-field
        class="invisible-input"
        :error-messages="errorMessages"
      >
      </v-text-field>
    </v-col>
    <v-col cols="10" class="label-btn">
      {{label}}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'OnOffInput',
  props: {
    value: {
      type: Boolean
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data: function () {
    return {}
  },
  created () {
    this.content = this.value
  },
  methods: {
    toggleValue () {
      this.content = !this.content
    }
  },
  computed: {
    content: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>
.label-btn {
  line-height: 37px;
  font-weight: 300;
  color: rgba(0,0,0,0.54);
}

.active-btn {
  width: 23px;
  height: 23px;
}

.active-btn i {
  line-height: 1.25em;
}
</style>
<style>
.invisible-input .v-input__slot {
  visibility: hidden;
  margin: 0;
  height: 0;
}

.invisible-input.v-input {
  padding: 0;
  margin: 0;
  width: max-content;
}
</style>
