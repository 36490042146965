
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component({})
export default class LineAutoplugForm extends Vue {
  @Prop({ default: false })
  hasRemove: boolean

  @Prop({ default: false })
  infoMessage: string

  @Emit('remove')
  emitRemove () {}
}
