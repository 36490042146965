<template>
  <v-dialog eager v-model="dialog" :width="actualFormWidth" persistent>
    <BaseEditCardEntity
      :entity="currentEntity"
      :tab-items="editCardItems"
      entity-type="holding"
      :is-edit="isEditForm"
      v-on:close="closeNewHolding"
      :save-item="saveHolding"
      :key="currentEntity.id"
      :is-loading="loadingHolding"
      :active-tab.sync="activeTab"
      ref="holding-card-entity"
      v-on:update:activeTab="changeFormWidth($event)"
    >
      <template v-slot:CRM="{ entity }">
        <DisabledFieldsAlertMessage
          :condition="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
          statusNeededText="Admin & Finance"
        />
        <v-row wrap>
          <v-col cols="12">
            <v-text-field
              label="Name"
              v-model.trim="entity.name"
              required
              :error-messages="errorMessageName(entity.name)"
              :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="ID"
              :value="entity.id"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <ChildEntityAutocomplete
              v-model="entity.metaHolding"
              :entitySearchType="'metaholding'"
              ref="meta-holding-auto"
              :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
              :allow-removing="hasCurrentUserRights(UserStatus.IS_ADMIN_AND_FINANCE)"
            >
            </ChildEntityAutocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:PROPERTIES="{ entity }">
        <DisabledFieldsAlertMessage
          :condition="disabledIfNoRights(UserStatus.IS_AM)"
          statusNeededText="Account Manager"
        />
        <v-row wrap>
          <v-col cols="12">
            <v-select
              label="Fit Score"
              :items="[{text: 'enabled', value: true}, {text: 'disabled', value: false}]"
              v-model="entity.hasFitScore"
              required
              :disabled="disabledIfNoRights(UserStatus.IS_AM)"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              label="Insight"
              :items="[{text: 'enabled', value: true}, {text: 'disabled', value: false}]"
              v-model="entity.hasInsight"
              required
              :disabled="isInsightDisabled || disabledIfNoRights(UserStatus.IS_AM)"
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:COMPANIES="{ entity }">
        <DisabledFieldsAlertMessage
          :condition="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
          statusNeededText="Admin & Finance"
        />
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text @click="createNewCompany" :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)">
              <v-icon>
                add
              </v-icon>
              Add new company
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-data-table
          :items="companiesOfHolding"
          :headers="headerCompany"
          :loading="loadingCompanies"
          class="children-table"
          :footer-props="{
            itemsPerPageOptions: [20,50,100, {text: 'All', value: -1}]
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>
                {{ props.item.name }}
              </td>
              <td>
                <v-btn icon @click="editCompany(props.item)" :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <template v-slot:BILLING="{ entity }">
        <BillingForm
          :entity="entity"
          :entity-type="'holding'"
          ref="billingForm"
          :new-form="defaultEntity ? true : false"
          v-on:close="closeNewHolding"
        >
        </BillingForm>
      </template>
      <template v-slot:HISTORY="{ entity }">
        <HistoryLogsComponent
          :entityId="currentEntity.id"
          entityType="holding"
        ></HistoryLogsComponent>
      </template>
    </BaseEditCardEntity>
  </v-dialog>
</template>

<script>
import BaseEditCardEntity from '@/components/KeystoneV2/BaseEditCardEntity'
import { rulesMixin } from '@/mixins/rulesMixin'
import { entityFormMixin } from '@/mixins/entityFormMixin'
import HoldingModel from '@/models/Keystone_v2/HoldingModel'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import BillingAccountModel from '@/models/Keystone_v2/BillingAccountModel'
import BillingForm from '@/components/TableComponents/Form/BillingForm'
import { callEditFormMixin } from '@/mixins/callEditFormMixin'
import store from '../../../../store'
import { common } from '../../../../store/modules/common'
import ChildEntityAutocomplete from '@/components/KeystoneV2/tools/ChildEntityAutocomplete'
import { snakeCaseConverterMixin } from '@/mixins/snakeCaseConverterMixin'
import DisabledFieldsAlertMessage from '@/components/KeystoneV2/tools/DisabledFieldsAlertMessage'
import { UserStatus } from '../../../../types/user_types'
import HistoryLogsComponent from '../../TableComponents/Form/FormFragments/HistoryLogs.vue'

if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'HoldingForm',
  components: { BaseEditCardEntity, ChildEntityAutocomplete, BillingForm, DisabledFieldsAlertMessage, HistoryLogsComponent },
  mixins: [rulesMixin, entityFormMixin, callEditFormMixin, snakeCaseConverterMixin],
  props: {},
  data: function () {
    return {
      formsWidth: {
        'default': '600px',
        'billing': '1200px',
        'history': '1000px'
      },
      actualFormWidth: '600px',
      editCardItems: [
        { title: 'CRM' },
        { title: 'PROPERTIES' },
        { title: 'COMPANIES', disabled: () => { return !this.isEditForm } },
        { title: 'BILLING' },
        { title: 'HISTORY', disabled: () => { return !this.isEditForm } }
      ],
      loadingHolding: false,
      loadingCompanies: false,
      companiesOfHolding: [],
      headerCompany: [
        { text: 'name', value: 'name', width: '80%' },
        { text: 'edit', value: '', sortable: false, width: '20%' }
      ],
      entityType: 'holding',
      processingHolding: false,
      activeTab: 0,
      UserStatus: UserStatus
    }
  },
  mounted () {
    if (this.$store.getters.getEditBilling) {
      this.activeTab = 3
      this.changeFormWidth(3)
    }
  },
  methods: {
    errorMessageIntegrity (name) {
      if (!this?.$store?.getters?.getHoldings) {
        return []
      }
      let holdingsName = this.getEntitiesNameToTest(this.$store.getters.getHoldings)
      return this.tempErrorMessageIntegrity(name, holdingsName)
    },
    errorMessageName (name) {
      return [...this.errorMessageIntegrity(name), ...this.errorMessageValueCantBeEmpty(name, 'name')]
    },
    defineMsaTypeItemsForNewForm () {
      this.$refs.billingForm.defineMsaTypeItems('holding')
    },
    closeNewHolding () {
      this.dialog = false
      this.companiesOfHolding = []
      this.activeTab = 0
      this.actualFormWidth = this.formsWidth.default
      this.callEditFormFunc(
        'openNewFormCompany',
        'openEditFormCompany'
      )
    },
    /**
     * @param holding {HoldingModel}
     * @return {Promise<void>}
     */
    async saveHolding (holding) {
      this.processingHolding = true

      let options = {
        name: holding.name,
        has_fit_score: Boolean(holding.hasFitScore),
        has_insight: Boolean(holding.hasInsight),
        meta_holding_id: holding.metaHolding && holding.metaHolding.id ? holding.metaHolding.id : null
      }

      options.billing_account = this.snakeCaseConverter(this.$refs.billingForm.fetchData())
      let result = null
      let success = false

      if (this.isEditForm) {
        options.id = holding.id
        result = await this.$store.dispatch('updateHolding', options)
      } else {
        result = await this.$store.dispatch('createHolding', options)
      }

      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when process')
        this.$store.commit('setErrorMessageWithResponse', result)
        const barConfig = {
          type: 'error',
          message: 'Error on update or create holding'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      } else {
        console.warn('Process success')
        success = true
        const barConfig = {
          type: 'success',
          message: 'Success !'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      }

      this.processingHolding = false
      this.$emit('refresh')

      if (!this.isEditForm && success) {
        this.currentEntity = new HoldingModel(result.data)
        this.$emit('update:isEditForm', true)
        this.activeTab = 2
      } else {
        this.closeNewHolding()
      }
    },
    async getCurrentEntity (holdingId) {
      this.loadingHolding = true
      this.currentEntity = new HoldingModel()

      const result = await this.$apiCaller.getHolding(holdingId)
      if (this.$apiCaller.isResponseError(result)) {
        this.$store.commit('setErrorMessageWithResponse', result)
        this.closeNewHolding()
      } else {
        this.currentEntity = new HoldingModel(result.data)
      }

      this.loadingHolding = false
    },
    async createNewCompany () {
      this.loadingHolding = true
      let cloned = this.$commonUtils.copyInstance(this.currentEntity)
      let result = await this.$apiCaller.getBillingAccountFromEntity('holding', this.currentEntity.id)
      if (this.$apiCaller.isResponseError(result)) {
        this.$store.commit('setErrorMessageWithResponse', result)
        this.loadingHolding = false
        this.closeNewHolding()
      } else {
        cloned.billingAccount = new BillingAccountModel(result.data)
        this.activeCallNewForm(cloned)
        this.loadingHolding = false
        this.$refs['holding-card-entity'].__closeDialogProcess()
      }
    },
    /**
     * @param company {CompanyModel}
     */
    editCompany (company) {
      this.activeCallEditForm(company)
      this.$refs['holding-card-entity'].__closeDialogProcess()
    },
    async getChildOfCurrentEntity () {
      this.loadingCompanies = true
      this.companiesOfHolding = []
      const data = {
        parent_id: this.currentEntity.id
      }
      const response = await this.$apiCaller.getCompanies(data)
      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling companies of holding')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        for (let i in response.data) {
          if (response.data.hasOwnProperty(i)) {
            this.companiesOfHolding.push(new CompanyModel(response.data[i]))
          }
        }
      }

      this.loadingCompanies = false
    },
    changeFormWidth (value) {
      if (value === 3) {
        this.actualFormWidth = this.formsWidth.billing
      } else if (value === 4) {
        this.actualFormWidth = this.formsWidth.history
      } else {
        this.actualFormWidth = this.formsWidth.default
      }
    },
    disabledIfNoRights (roles = null) {
      return !this.hasCurrentUserRights(roles)
    },
    hasCurrentUserRights (roles = null) {
      return this.$commonUtils.hasUserCorrectStatus(this.$store.getters.getUserStatus, roles)
    }
  },
  computed: {
    isInsightDisabled () {
      return !this.$store.getters.isUserSettupper
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.text-left {
  text-align: left;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.redirect-slack {
  text-decoration: none;
}
.redirect-slack:hover {
  text-decoration: underline;
}
</style>
