
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Autoplugs } from '../../../../../types/autoplugs_type'
import CollaboratorModel from '@/models/Keystone_v2/CollaboratorModel'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import { getDspTextForValue } from '../../../../../utils/keystoneV2Utils'

@Component({})
export default class AutoplugInfo extends Vue {
  @Prop()
  autoplug: Autoplugs

  @Prop()
  seatsOfCompany: SiegeSettingModel[]
  replaceWithOr (str: string) {
    return str.replaceAll(',', ' or ')
  }

  removeStrong (str: string) {
    return str.replaceAll('<strong>', '').replaceAll('</strong>', '')
  }

  get textMail () {
    const coll: CollaboratorModel[] = this.$store.getters.getCollaborators
    const collList = this.autoplug.list_collaborators.map((c: number) => {
      const _c: CollaboratorModel = coll.find(item => item.id === c)
      return _c ? this.$commonUtils.htmlEntities(_c.email) : null
    }).filter(mail => !!mail)
    return `This Autoplug Rule will alert <strong>${collList.join('</strong> and <strong>')} </strong> when finding IOs respecting :`
  }

  get textDsp () {
    return `-Is on <strong>DSP</strong> ${this.autoplug.list_dsp.map((item) => {
      return this.$commonUtils.htmlEntities((getDspTextForValue(item)))
    }).join(' or ')}`
  }

  get textSeat () {
    return `-belong to <strong>Seats</strong> ${this.replaceWithOr(this.$commonUtils.htmlEntities(this.autoplug.seats_name))}`
  }

  get textFitscore () {
    return `-<strong>fitscore</strong> is above ${this.autoplug.min_fitscore}`
  }

  get textAdvertiser () {
    return `-belong to <strong>Advertiser</strong> ${this.replaceWithOr(this.$commonUtils.htmlEntities(this.autoplug.advertisers_name))}`
  }

  get textKpi () {
    return `-<strong>KPI goal</strong> ${this.autoplug.list_kpis.map(item => this.$commonUtils.htmlEntities(item)).join(' or ')}`
  }

  get textAdvertiserToExclude () {
    return `-does not belong to <strong>Advertiser</strong> ${this.replaceWithOr(this.$commonUtils.htmlEntities(this.autoplug.advertisers_to_exclude_name))}`
  }

  get textContains () {
    return `-<strong>name</strong> contains "${this.autoplug.contains.map(item => this.$commonUtils.htmlEntities(item)).join('" or "')}"`
  }

  get textDoesNotContains () {
    return `-<strong>name</strong> does not contains "${this.autoplug.does_not_contain.map(item => this.$commonUtils.htmlEntities(item)).join('" or "')}"`
  }

  get textStartDate () {
    return `-started spending after the <strong>${this.autoplug.start_date}</strong>`
  }

  get textIoToExclude () {
    return `-<strong>id</strong> is not ${this.replaceWithOr(this.$commonUtils.htmlEntities(this.autoplug.io_to_exclude_external_id))}`
  }

  get textMediacost () {
    return `-<strong>Daily Media cost</strong> is more than ${this.autoplug.min_media_cost}`
  }
}
