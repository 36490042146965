import { $APPNEXUS, $BEESWAX, $DBM, $MEDIAMATH, $THETRADEDESK, $YOUTUBE } from '../../../../../config/dspConfig'

/**
 * kpi by dsp dicts for autoplugs and some helper function
 */
export const kpiByDspAutoplugs = {
  [$APPNEXUS]: [
    'CPA',
    'CTR',
    'CPC',
    'CPM',
    'other'
  ],
  [$DBM]: [
    'CPA',
    'CTR',
    'CPC',
    'CPM',
    'CPIAVC',
    'other',
    'CPVideoView'
  ],
  [$MEDIAMATH]: [
    'CPA',
    'CTR',
    'CPC',
    'other',
    'VTR',
    'CPM'
  ],
  [$THETRADEDESK]: [
    'CPM',
    'CPC',
    'CTR',
    'CPA',
    'other',
    'VTR',
    'V-CPM',
    'CPCV'
  ],
  [$BEESWAX]: [
    'CPM',
    'CPC',
    'VTR',
    'CPA'
  ],
  [$YOUTUBE]: [
    'CPVideoView',
    'CPMBumper'
  ]
}

export function getKpiByDsp (dspList: string[]) {
  let kpiByDsp: string[] = []
  for (let dsp of dspList) {
    if (dsp in kpiByDspAutoplugs) {
      let kpi = kpiByDspAutoplugs[dsp as KeysOf<typeof kpiByDspAutoplugs>]
      kpiByDsp = [...kpiByDsp, ...kpi]
    }
  }
  return kpiByDsp
}

export function getKpiItems ():TextValue[] {
  let kpiItems: string[] = []
  for (let kpiByDspAutoplugsKey in kpiByDspAutoplugs) {
    let kpi = kpiByDspAutoplugs[kpiByDspAutoplugsKey as KeysOf<typeof kpiByDspAutoplugs>]
    kpiItems = [...kpiItems, ...kpi]
  }

  return [...kpiItems].map(item => {
    return {
      text: item,
      value: item
    }
  })
}
