import { HistoryLogsApiArgs, HistoryLogsContentApiArgs } from '../../../types/history_logs_type'

export class HistoryLogsContentModel {
  id: number
  entityId: number
  entityType: string
  logs: string

  constructor (logsContentApiOject: HistoryLogsContentApiArgs = null) {
    if (logsContentApiOject != null) {
      this.id = logsContentApiOject.id
      this.entityId = logsContentApiOject.entity_id
      this.entityType = logsContentApiOject.entity_type
      this.logs = logsContentApiOject.logs
    }
  }
}
export default class HistoryLogsModel {
  id: number
  transactionId: string
  modificationDate: Date
  userId: number
  userMail: string
  entityId: number
  content: Array<HistoryLogsContentModel>
  show: boolean = false

  constructor (logsApiObject: HistoryLogsApiArgs = null) {
    if (logsApiObject != null) {
      this.id = logsApiObject.id
      this.transactionId = logsApiObject.transaction_id
      this.modificationDate = logsApiObject.modification_date
      this.userId = logsApiObject.user_id
      this.userMail = logsApiObject.user_mail
      this.content = logsApiObject.content.map((elem: any) => new HistoryLogsContentModel(elem))
    }
    this.show = false
  }
}
