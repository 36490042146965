
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class HistoryLogLine extends Vue {
  @Prop({ required: false, default: null }) oldLine: string
  @Prop({ required: false, default: null }) newLine: string
  @Prop({ required: false, default: null }) itemKey: string

  formatTitle (str: string) {
    // Special case for Keystone Settings, New Feature Reference and ID
    if (str.toLowerCase() === 'keystone_settings_siege') {
      return 'Keystone Seat Properties'
    } else if (str.toLowerCase() === 'keystone_settings_member') {
      return 'Keystone Member Properties'
    } else if (str.toLowerCase() === 'new_feature_ref_member') {
      return 'New Feature References'
    } else if (str.toLowerCase() === 'id') {
      return 'ID'
    }
    return str.toLowerCase().split('_').map((word: string) => word.charAt(0).toUpperCase() + word.toLowerCase().substring(1)).join(' ')
  }

  formatValue (value: string | number | boolean): string | number {
    if (typeof value === 'boolean') {
      return value.toString().charAt(0).toUpperCase() + value.toString().toLowerCase().substring(1)
    }
    return value
  }
}
