
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import OnOffInput from '../tools/OnOffInput.vue'
import MarkupModuleForm from '../../Common/MarkupModule.vue'
import { MarkupModuleModel } from '../../../models/Keystone_v2/MarkupModuleModel'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'

@Component({
  components: {
    OnOffInput,
    MarkupModuleForm
  }
})
export default class KeystoneMarkupModuleForm extends Vue {
  @Prop({ required: false, default: null }) model: MarkupModuleModel
  @Prop({ required: false, default: null }) dsp: string
  @Prop({ required: false, default: null }) keystoneSettingSiege: SiegeSettingModel

  resetMarkupModule () {
    const ref: MarkupModuleForm = this.$refs.markupModule as MarkupModuleForm
    ref.resetMarkupModule()
  }
}
