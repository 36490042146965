

import Vue from 'vue'
import Component from 'vue-class-component'
import {
  MSA,
  MsaItemObjectType,
  InvoiceDataGroupingTypes,
  ScibidsRibTypes,
  InvoiceCurrencyTypes,
  RebateModes,
  TypologyChoices,
  MSA_TYPES_WITH_INHERITANCE
} from '../../../../types/billing_account_types'
import { ICountry } from '../../../../types/country_types'
import CompanyModel from '../../../models/Keystone_v2/CompanyModel'
import HoldingModel from '../../../models/Keystone_v2/HoldingModel'
import MetaHoldingModel from '../../../models/Keystone_v2/MetaHoldingModel'
import BillingAccountModel from '../../../models/Keystone_v2/BillingAccountModel'
import BillingEntityModel, { DEFAULT_VALUE_PAYMENT_TERM, DEFAULT_VALUE_PAYMENT_TERM_DV360 } from '../../../models/Keystone_v2/BillingEntityModel'
import ContractModel from '../../../models/Keystone_v2/ContractModel'
import RatecardModel from '../../../models/Keystone_v2/RatecardModel'
import FeeRateModel from '../../../models/Keystone_v2/FeeRateModel'
import { Prop, Watch } from 'vue-property-decorator'
import * as rules from '../../../../src/rules/rules'
import DatePicker from './FormFragments/DatePicker.vue'
import MailChipInput from './FormFragments/MailChipInput.vue'
import FeeRateForm from './FormFragments/FeeRateForm.vue'
import DisabledFieldsAlertMessage from '../../KeystoneV2/tools/DisabledFieldsAlertMessage.vue'
import { UserStatus } from '../../../../types/user_types'
import SimpleDialog from '../Form/FormFragments/SimpleDialog.vue'
import * as keystoneV2Utils from '../../../../utils/keystoneV2Utils'

export type EntityType = MetaHoldingModel | HoldingModel | CompanyModel

@Component({
  components: {
    DatePicker: DatePicker,
    MailChipInput: MailChipInput,
    FeeRateForm: FeeRateForm,
    DisabledFieldsAlertMessage: DisabledFieldsAlertMessage,
    SimpleDialog: SimpleDialog
  }
})
export default class BillingForm extends Vue {
  @Prop() entity: EntityType
  @Prop() entityType: string

  billingAccount = new BillingAccountModel()
  rules = rules
  isBillingEditForm: boolean = false
  activeBillingAccountLock: boolean = false

  originalMsaType: string = null
  showWarningWillInheritFromParent: boolean = false
  showWarningWillLoseInheritance: boolean = false

  // BOOLEANS
  done: boolean = false
  refreshMsaItems: boolean = false
  refresh: boolean = false

  // ENUMS FOR SELECTS / AUTOCOMPLETES / TABLES
  invoiceDataGroupingItems = Object.entries(InvoiceDataGroupingTypes).map(e => {
    return { text: e[1], value: e[0] }
  })
  typologyItems = TypologyChoices
  scibidsRibItems = Object.entries(ScibidsRibTypes).map(e => {
    return { text: e[1], value: e[0] }
  })
  invoiceCurrencyItems = Object.entries(InvoiceCurrencyTypes).map(e => {
    return { text: e[1].toString() + ' - ' + e[0].toString(), value: e[0] }
  })
  msaItems: Array<MsaItemObjectType>
  ratecardRebateModeItems: Array<{ text: RebateModes, value: string }> = Object.entries(RebateModes).map(e => {
    return { text: e[1], value: e[0] }
  })
  invoiceAttachmentModeItems: Array<{text: string, value: string}> = [
    { text: 'None', value: 'NONE' },
    { text: 'Cc', value: 'CC' },
    { text: 'Bcc', value: 'BCC' }
  ]

  // PANELS
  showBillingEntity: boolean = false
  showRatecard: boolean = false
  showFeeRate: boolean = true

  entitiesDialogForm: {[key: string]: boolean} = {
    'metaholding': false,
    'holding': false,
    'company': false
  }

  // COPY / PASTE BILLING ENTITIES
  copyBillingEntityClicked: boolean = false

  mailChipInputsRefs = ['invoiceTo', 'invoiceCc', 'invoiceBcc']

  created () {
    this.createObjects()
  }

  createObjects () {
    console.log('entity', this.entity)
    if (this.entity.billingAccount && this.entity.billingAccount.id) {
      this.billingAccount.id = this.entity.billingAccount.id
      this.isBillingEditForm = true
    } else {
      this.isBillingEditForm = false
    }
    this.fillBillingAccountData()
    this.originalMsaType = this.billingAccount.msaType
    this.convertObjects()
    this.defineMsaTypeItems()
    this.defaultValues()
    // this.getSaleInCharge()
  }

  convertObjects () {
    this.convertAllDates(true)
    this.convertAllInvoiceRecipients(false)
    this.billingAccount.ratecard.feeRate.tierConditions = this.convertTierConditions(this.billingAccount.ratecard.feeRate.tierConditions, false)
  }

  isRequiredIfActive (formPart: string, conditionalField: any = null): boolean {
    if (!this.billingAccount.active) { return false }
    if (formPart === 'contract' && (this.isContractFieldDisabled || !['', null, undefined].includes(conditionalField))) {
      return false
    }
    if (formPart === 'billing entity' && (this.isBillingEntityFieldDisabled || !['', null, undefined].includes(conditionalField))) {
      return false
    }
    if (formPart === 'ratecard' && (this.isRatecardFieldDisabled || !['', null, undefined].includes(conditionalField))) {
      return false
    }
    return true
  }

  errorRequiredField (item: any, formPart: string = '', conditionalField: any = null) {
    if (item == null) { return '' }
    let ruleCheck: string | boolean = item !== 0 ? rules.required(item) : true
    let toReturn = this.isRequiredIfActive(formPart, conditionalField) && ruleCheck !== true ? 'Field is required' : ''
    return toReturn
  }

  defineMsaTypeItems (forceModel: string = null) {
    if (this.isMetaHolding || (forceModel && forceModel === 'metaholding')) {
      this.msaItems = [{ text: MSA.MULTI_WITH_AAA.text, value: MSA.MULTI_WITH_AAA.value }]
    } else if (this.isHolding || (forceModel && forceModel === 'holding')) {
      this.msaItems = Object.entries(MSA).map(line => {
        if (line[1].value !== MSA.MULTI_WITH_AAA.value) {
          return line[1] // [KEY, { text: xxx, value: xxx }]
        }
      }).filter(x => x != null) // to prevent a "null" item in place of MSA.MULTI_WITH_AAA
    } else {
      this.msaItems = Object.entries(MSA).map(line => {
        return line[1] // [KEY, { text: xxx, value: xxx }]
      })
    }
  }

  defaultValues () {
    if (this.billingAccount.billingEntity.firstLineCurrency == null) {
      this.billingAccount.billingEntity.firstLineCurrency = 'USD'
    }
    if (this.billingAccount.billingEntity.scibidsRib == null) {
      this.billingAccount.billingEntity.scibidsRib = 'hsbc_dv'
    }
  }

  getBillingEntityLabel (id: number) {
    return (id !== undefined && id !== null) ? `Billing Entity Name (ID: ${id})` : 'Billing Entity Name'
  }

  activateAndLockBillingAccount (parentEntity: EntityType) {
    if (parentEntity && parentEntity?.billingAccount?.active === true) {
      this.billingAccount.active = true
      this.activeBillingAccountLock = true
    }
  }

  fillBillingAccountData () {
    if (this.isCompany) {
      let company = this.entity as CompanyModel
      if (MSA_TYPES_WITH_INHERITANCE.includes(company.holding.billingAccount.msaType)) {
        this.billingAccount = company.holding.billingAccount as BillingAccountModel
      } else {
        this.billingAccount.msaType = company.holding.billingAccount.msaType
        this.billingAccount.contract = company.holding.billingAccount.contract as ContractModel
        this.billingAccount.billingEntity = company.billingAccount.billingEntity as BillingEntityModel
        this.billingAccount.ratecard = company.holding.billingAccount.ratecard as RatecardModel
      }
      this.activateAndLockBillingAccount(company?.holding)
    } else if (this.isHolding) {
      this.billingAccount = this.entity.billingAccount as BillingAccountModel
      if (this.entity?.billingAccount?.msaType === MSA.MULTI_WITHOUT_AAA.value) {
        this.billingAccount.billingEntity = new BillingEntityModel()
      }
      this.activateAndLockBillingAccount((this.entity as HoldingModel)?.metaHolding)
    } else if (this.isMetaHolding) {
      this.billingAccount = this.entity.billingAccount as BillingAccountModel
      this.billingAccount.billingEntity = new BillingEntityModel()
    }
  }

  // SHOW PANELS
  showFormPanel (clickedOn: string) {
    if (clickedOn === 'billingEntity') {
      this.showBillingEntity = !this.showBillingEntity
      this.showRatecard = false
    } else if (clickedOn === 'ratecard') {
      this.showRatecard = !this.showRatecard
      this.showBillingEntity = false
    }
  }

  msaTypeChanged () {
    if (this.entity.billingAccount && this.entity.billingAccount.id) { this.fillBillingAccountData() }
    if (this.originalMsaType != null) {
      if (this.originalMsaType === this.billingAccount.msaType) {
        this.showWarningWillInheritFromParent = false
        this.showWarningWillLoseInheritance = false
      } else if (this.originalMsaType === MSA.MULTI_WITHOUT_AAA.value && this.billingAccount.msaType !== MSA.MULTI_WITHOUT_AAA.value) {
        this.showWarningWillInheritFromParent = true
        this.showWarningWillLoseInheritance = false
      } else if (this.originalMsaType !== MSA.MULTI_WITHOUT_AAA.value && this.billingAccount.msaType === MSA.MULTI_WITHOUT_AAA.value) {
        this.showWarningWillInheritFromParent = false
        this.showWarningWillLoseInheritance = true
      }
    }
    if ([MSA.XANDR.value, MSA.TTD.value, MSA.DV360.value].includes(this.billingAccount.msaType)) {
      this.billingAccount.billingEntity.fillForPartnership(this.billingAccount.msaType, this.entity.name)
      // Country refresh
      const countryFound: Array<ICountry> = this.$store.getters.getCountries.filter((country: ICountry) => {
        if (country.id === this.billingAccount.billingEntity.countryId) {
          return country
        }
      })
      this.billingAccount.billingEntity.country = countryFound.length === 1 ? countryFound[0] : null
      // Invoices refresh
      this.mailChipInputsRefs.forEach((refName: string) => {
        (this.$refs[refName] as any).reload(this.billingAccount.billingEntity[refName as keyof BillingEntityModel])
      })
      this.refresh = !this.refresh
    }

    if (this.billingAccount.msaType === MSA.DV360.value) {
      this.billingAccount.billingEntity.paymentTerm = DEFAULT_VALUE_PAYMENT_TERM_DV360
    } else if ([MSA.XANDR.value, MSA.TTD.value].includes(this.billingAccount.msaType)) {
      this.billingAccount.billingEntity.paymentTerm = DEFAULT_VALUE_PAYMENT_TERM
      this.billingAccount.billingEntity.dv360TcUpdateContactEmail = null
    }
  }

  // CONVERTERS to be used before pushing objetcs to the API (types may differ between what we want and ahat the components give us)
  convertAllDates (toString = false) {
    this.billingAccount.contract.startDate = this.convertDate(this.billingAccount.contract.startDate, toString)
    this.billingAccount.contract.endDate = this.convertDate(this.billingAccount.contract.endDate, toString)
    this.billingAccount.ratecard.expectedDateOfRatecardChange = this.convertDate(this.billingAccount.ratecard.expectedDateOfRatecardChange, toString)
    this.billingAccount.ratecard.trialPeriodEndDate = this.convertDate(this.billingAccount.ratecard.trialPeriodEndDate, toString)
  }
  convertDate (obj: string, toString = false) : string | Date {
    if (toString && obj != null) {
      return (obj as string).substring(0, 10)
    } else if (!toString && obj != null) {
      return new Date(obj).toISOString()
    } else {
      return obj
    }
  }
  convertTierConditions (obj: Array<any>, toSend = false): Array<any> {
    let result: Array<any> = []
    if (obj == null) {
      return [null]
    } else if (!toSend) {
      if (this.areTierConditionsTable) {
        if (!(obj.map((tier: { [key: string]: any }) => Object.keys(tier).length === 3)).every((elem: boolean) => elem === true)) {
          return [null]
        } else {
          obj.forEach((tier: any) => {
            result.push({ from: Number(tier['value1']), to: Number(tier['value2']), rate: Number(tier['value3']), infinite: Number(tier['value2']) === -1 })
          })
        }
      } else {
        result = obj[0].value1
      }
      return result
    } else {
      if (this.areTierConditionsTable) {
        obj.forEach(tier => {
          result.push({
            value1: Number(tier.from),
            value2: Number(tier.to),
            value3: Number(tier.rate)
          })
        })
      } else {
        result.push({
          value1: Number(obj)
        })
      }
      return result
    }
  }

  convertAllInvoiceRecipients (toString = false) {
    type KeysOf<T> = keyof T & string
    let invoices: Array<KeysOf<BillingEntityModel>> = ['invoiceTo', 'invoiceCc', 'invoiceBcc', 'resellerEmailAddress', 'dv360TcUpdateContactEmail']
    invoices.forEach((invoice: KeysOf<BillingEntityModel>) => {
      const currentInvoice = this.billingAccount.billingEntity.getProp(invoice)
      if (currentInvoice != null) {
        this.billingAccount.billingEntity.setProp(
          invoice, this.convertInvoiceRecipients((currentInvoice as string | string[]), toString)
        )
      }
    })
  }

  convertInvoiceRecipients (obj: string | Array<string>, toString = false): string | Array<string> {
    if (toString && obj != null && Array.isArray(obj)) {
      return (obj as Array<string>).join(',').replace(/\s/g, '')
    } else if (!toString && obj != null && typeof obj === 'string') {
      let array = (obj as string).split(',')
      array.forEach((recipient: string) => {
        recipient.trim()
      })
      return array
    } else {
      return obj
    }
  }

  // GETTERS
  get disabledIfNoRights () {
    return !this.$commonUtils.hasUserCorrectStatus(this.$store.getters.getUserStatus, [UserStatus.IS_ADMIN_AND_FINANCE])
  }
  get disabledIfNotAdminAndFinanceOnly () {
    return !this.$commonUtils.hasUserCorrectStatus(this.$store.getters.getUserStatus, [UserStatus.IS_ADMIN_AND_FINANCE], true)
  }
  get hasMissingObject () {
    if (this.entity == null || this.billingAccount?.contract == null || this.billingAccount?.billingEntity == null) {
      return true
    }
    if (this.isCompany) {
      return [null, undefined].includes((this.entity as CompanyModel).holding)
    }
    if (this.isHolding) {
      return this.billingAccount.msaType === 'AAA' && [null, undefined].includes((this.entity as HoldingModel).metaHolding)
    }
    return false
  }
  get isAllDisabled () {
    return this.isContractFieldDisabled && this.isBillingEntityFieldDisabled
  }
  get isContractFieldDisabled () {
    return this.isCompany
  }
  get isBillingEntityFieldDisabled () {
    if (this.isCompany) {
      let company = this.entity as CompanyModel
      const msaTypesNoMultiNoAaa: Array<string> = Object.entries(MSA).map(([key, entry]) => {
        if (key !== 'MULTI_WITHOUT_AAA') {
          return entry.value
        }
      })
      return (
        !company.holding ||
        !this.hasRelatedHoldingInfo ||
        msaTypesNoMultiNoAaa.includes(company.holding.billingAccount.msaType)
      )
    } else if (this.isHolding && this.billingAccount.msaType === MSA.MULTI_WITHOUT_AAA.value) {
      return true
    } else if (this.isMetaHolding) {
      return true
    }
    return false
  }
  isBillingEntityNull () {
    return (
      (this.isHolding && this.billingAccount.msaType === MSA.MULTI_WITHOUT_AAA.value) ||
      this.isMetaHolding
    )
  }
  get isRatecardFieldDisabled () {
    return this.isCompany
  }
  get hasRelatedHoldingInfo () {
    return !(this.isCompany && !(this.entity as CompanyModel)?.holding?.billingAccount?.msaType)
  }
  get areTierConditionsTable () {
    return ['VOLUME_RATE', 'TIERED_RATE', 'SOW'].includes(this.billingAccount.ratecard.feeRate.feeType)
  }
  get getSalesItems () {
    let sales = keystoneV2Utils.collaboratorFilterToSelectItem(this.$store.getters.getCollaborators, 'sale')
    let salesToReturn: Array<{[key: string]: any}> = []
    sales.forEach((sale: {[x: string]: any}) => {
      const mail = sale.dv_mail != null ? sale.dv_mail : sale.email
      salesToReturn.push({ id: sale.value, name: this.$commonUtils.getCollaboratorNameFromMail(mail) })
    })
    return salesToReturn
  }

  get isMsaTypeDv360 () {
    return this.billingAccount.msaType === MSA.DV360.value
  }

  contractSignerIs (companyName: string) {
    return this.billingAccount?.contract?.companyThatSignedTheContract === companyName
  }

  // FETCH FROM PARENT(S)
  fetchData () {
    this.done = true
    this.billingAccount.billingEntity.countryId = this.billingAccount?.billingEntity?.country?.id ? this.billingAccount.billingEntity.country.id : null
    this.billingAccount.billingEntity.saleInChargeId = this.billingAccount?.billingEntity?.saleInCharge?.id ? this.billingAccount.billingEntity.saleInCharge.id : null
    // Get data from date picker components if any (else will be <null>)
    this.convertAllDates(false)
    this.convertAllInvoiceRecipients(true)
    this.billingAccount.ratecard.feeRate.tierConditions = this.convertTierConditions(this.billingAccount.ratecard.feeRate.tierConditions, true)
    this.objectsValidationProcess()
    return this.billingAccount
  }

  // OBJECTS VALIDATION
  objectsValidationProcess () {
    if (!this.validateContractData()) { this.billingAccount.contract = null }
    if (!this.validateBillingEntityData()) { this.billingAccount.billingEntity = null }
    if (!this.validateRatecardData()) { this.billingAccount.ratecard = null }
    if (this.billingAccount.ratecard != null && !this.validateFeeRateData()) { this.billingAccount.ratecard.feeRate = null }
    if (!this.validateBillingAccountData()) { this.billingAccount = null }
  }
  validateContractData () {
    if (this.billingAccount.contract) {
      let contractValidatedData = Object.entries(this.billingAccount?.contract).map(entry => {
        if (!entry[0].startsWith('_') && entry[1] === '') { this.billingAccount.contract[entry[0] as keyof ContractModel] = null }
        return (!entry[0].startsWith('_') && entry[1] != null)
      })
      return contractValidatedData.includes(true)
    }
  }
  validateBillingEntityData () {
    if (this.billingAccount.billingEntity) {
      let billingEntityValidatedData = Object.entries(this.billingAccount?.billingEntity).map(entry => {
        if (!entry[0].startsWith('_') && entry[1] === '') {
          this.billingAccount.billingEntity.setProp(entry[0], null)
        }
        return (!entry[0].startsWith('_') && entry[1] != null)
      })
      return billingEntityValidatedData.includes(true)
    }
  }
  validateRatecardData () {
    if (this.billingAccount.ratecard) {
      let ratecardValidatedData = Object.entries(this.billingAccount?.ratecard).map(entry => {
        if (!entry[0].startsWith('_') && entry[1] === '') { this.billingAccount.ratecard[entry[0] as keyof RatecardModel] = null }
        return (!entry[0].startsWith('_') && entry[1] !== undefined)
      })
      return ratecardValidatedData.includes(true)
    }
  }
  validateFeeRateData () {
    if (this.billingAccount.ratecard.feeRate) {
      let feeRateValidatedData = Object.entries(this.billingAccount?.ratecard?.feeRate).map(entry => {
        if (!entry[0].startsWith('_') && entry[1] === '') { this.billingAccount.ratecard.feeRate[entry[0] as keyof FeeRateModel] = null }
        return (!entry[0].startsWith('_') && entry[1] !== undefined)
      })
      return feeRateValidatedData.includes(true)
    }
  }
  validateBillingAccountData () {
    return !(!this.billingAccount.contract && !this.billingAccount.billingEntity && !this.billingAccount.ratecard && !this.billingAccount.msaType)
  }

  // COPY / PASTE BILLING ENTITY DATA
  copyBillingEntity () {
    const toCopy = this.$commonUtils.deepCopy(this.billingAccount.billingEntity)
    delete toCopy.id // Remove ID
    this.copyBillingEntityClicked = true
    this.$store.commit('setCopiedBillingEntity', toCopy)
  }
  pasteCopiedBillingEntity () {
    this.billingAccount.billingEntity = this.$store.getters.getCopiedBillingEntity
    this.refresh = !this.refresh // for invoices mail chip input
  }

  // UTILITY
  get isCompany () {
    return this.entityType === 'company' && this.entity instanceof CompanyModel
  }
  get isHolding () {
    return this.entityType === 'holding' && this.entity instanceof HoldingModel
  }
  get isMetaHolding () {
    return this.entityType === 'metaholding' && this.entity instanceof MetaHoldingModel
  }

  @Watch('entity.metaHolding')
  onRelatedMetaHoldingOnHoldingChanged (newMeta: MetaHoldingModel, oldMeta: MetaHoldingModel) {
    if (oldMeta != null && newMeta != null && oldMeta.id !== newMeta.id) {
      this.defineMsaTypeItems()
    }
  }
  @Watch('entity.holding')
  async onRelatedHoldingOnCompanyChanged (newHolding: HoldingModel, oldHolding: HoldingModel) {
    if (oldHolding != null && newHolding != null && oldHolding.id !== newHolding.id) {
      let result = await this.$apiCaller.getBillingAccountFromEntity('holding', newHolding.id)
      console.warn('Related holding for company refreshed')
      let entity = this.entity as CompanyModel
      entity.holding.billingAccount = new BillingAccountModel(result.data)
      this.createObjects()
    } else if (newHolding != null && oldHolding == null) {
      console.warn('Related holding for company found, using related data')
      this.createObjects()
    }
  }
}
