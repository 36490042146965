<template>
  <v-row>
    <v-col cols="9">
      <v-checkbox
        v-model="model"
        :label="label"
        :color="color"
        hide-details
        :disabled="disabled"
        :readonly="readonly"
      ></v-checkbox>
    </v-col>
    <v-col v-if="infoText">
      <InfoTextIcon
        icon-class="icon-info-checkbox"
      >
        {{infoText}}
      </InfoTextIcon>
    </v-col>
  </v-row>
</template>

<script>
import InfoTextIcon from './InfoTextIcon'

export default {
  name: 'CheckBoxWithInfo',
  components: {
    InfoTextIcon
  },
  props: {
    value: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'success'
    },
    infoText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      model: ''
    }
  },
  mounted () {
    this.model = this.value
  },
  watch: {
    value (val) {
      if (val !== this.model) {
        this.model = val
      }
    },
    model (val) {
      this.$emit('input', val)
    }
  }
}
</script>
<style>
.icon-info-checkbox {
  line-height: 2.5em;
}
</style>
