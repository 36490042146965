import { DSPSettingsModelFactory } from '@/models/Keystone/DSPSettingsModel'

export const getDspSettingsMixin = {
  props: {},
  data: function () {
    return {
      isLoadingDspSettings: false,
      /**
       * @type DSPSettingsModel
       */
      dspSettings: null
    }
  },
  methods: {
    async getDspSettingsFunc (dsp, memberExternalId) {
      this.isLoadingDspSettings = true
      const response = await this.$apiCaller.getDspConfigs(dsp, {
        id: memberExternalId
      })
      this.isLoadingDspSettings = false

      if (this.$apiCaller.isResponseError(response, true)) {
        console.warn('ERROR when calling api dspSettings')
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
        this.dspSettings = null
        return
      }

      if (response.data.length === 0) {
        console.warn('can\'t find dspSetting.')
        this.dspSettings = null
        return
      }

      const dspSettingsFactory = new DSPSettingsModelFactory()

      this.dspSettings = dspSettingsFactory.getDspSettingsModel(dsp, response.data[0])
    }
  }
}
