
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class InfoTextIcon extends Vue {
  @Prop({ default: false, type: Boolean }) bottom: boolean
  @Prop({ default: false, type: Boolean }) top: boolean
  @Prop({ default: false, type: Boolean }) left: boolean
  @Prop({ default: false, type: Boolean }) right: boolean
  @Prop({ default: 0 }) closeDelay: number
  @Prop({ default: '' }) minWidth: string | number
  @Prop({ default: '', type: String }) iconClass: string

  /**
   * bottom is the default value.
   * When bottom, top, left and right are all set to false,
   * bottom will be set to true to the tooltip component.
   */
  get _bottom () {
    return this.bottom || (!this.bottom && !this.top && !this.left && !this.right)
  }
}
