

import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import * as rules from '../../../../../src/rules/rules'
import RatecardModel from '@/models/Keystone_v2/RatecardModel'
import FeeRateModel from '@/models/Keystone_v2/FeeRateModel'
import {
  FeeRateTypeItems,
  TierConditionsHeaders
} from '../../../../../types/billing_account_types'
import { roundNum } from '../../../../../utils/commonUtils'

export type TierConditionsType = {
  from: number,
  to: number,
  rate: number,
  infinite: boolean
}

@Component({})
export default class FeeRateForm extends Vue {
  @Prop()
  ratecard: RatecardModel
  @Prop()
  required: boolean
  @Prop()
  disabled: boolean

  rules = rules
  $commonUtils: any

  newTierConditionValid: boolean = true
  newTierConditions: TierConditionsType = {
    from: null,
    to: null,
    rate: null,
    infinite: null
  }
  previousFeeRateType: string = null
  cachedTierConditions: Array<TierConditionsType> = []
  refreshTierConditions: boolean = false
  isNewTierConditionsFormOn: boolean = false
  flatRate: boolean = false
  simulationMediaCost: number = null

  // ENUMS
  feeRateItems = Object.entries(FeeRateTypeItems).map(e => {
    return { text: e[1].toString(), value: e[0] }
  })
  tierConditionsHeaders: Array<{}> = []

  created () {
    if (this.ratecard.feeRate == null) {
      this.ratecard.feeRate = new FeeRateModel()
      this.ratecard.feeRate.tierConditions = []
    } else if (this.ratecard.feeRate.tierConditions == null) {
      this.ratecard.feeRate.tierConditions = []
    }
    this.previousFeeRateType = this.ratecard.feeRate.feeType ? this.ratecard.feeRate.feeType : null
    this.removeNullTierConditionValue()
    this.defineTierConditionsHeaders()
  }

  removeNullTierConditionValue () {
    if (this.ratecard.feeRate.tierConditions && this.ratecard.feeRate.tierConditions.length > 0) {
      if (this.ratecard.feeRate.tierConditions[0] == null) {
        this.ratecard.feeRate.tierConditions = []
      }
    }
  }

  defineTierConditionsHeaders () {
    this.tierConditionsHeaders = Object.entries(TierConditionsHeaders).map(e => {
      return { text: e[1].toString(), value: e[0], align: 'start' }
    })
    if (!this.isSowRate) {
      this.tierConditionsHeaders.push({ text: '', value: '', align: 'start' })
    }
  }

  // FEE RATE FOR RATECARD
  feeRateTypeChanged (newFeeType: string) {
    // Saving cached table values
    if (['TIERED_RATE', 'VOLUME_RATE', 'SOW'].includes(this.previousFeeRateType)) {
      if (this.ratecard.feeRate.tierConditions && this.ratecard.feeRate.tierConditions.length > 0) {
        this.cachedTierConditions = this.ratecard.feeRate.tierConditions
      }
    }

    // Retrieving cached values fro 'table' rates
    if (this.isTableRate) {
      this.ratecard.feeRate.tierConditions = this.cachedTierConditions
    } else {
      this.ratecard.feeRate.tierConditions = []
    }
    this.defineTierConditionsHeaders()
    this.previousFeeRateType = newFeeType
  }
  cancelNewTierConditions () {
    this.isNewTierConditionsFormOn = false
    this.resetNewTierConditions()
  }
  disabledTierConditionFrom () {
    return this.ratecard.feeRate.tierConditions && this.ratecard.feeRate.tierConditions.length >= 1
  }
  deleteTableLine (item: any) {
    let index = this.ratecard.feeRate.tierConditions.indexOf(item)
    if (index !== -1) {
      this.ratecard.feeRate.tierConditions.splice(index, 1)
      this.refreshTierConditions = !this.refreshTierConditions
    }
  }

  // CHECK DATA TABLE
  isConditionFieldIncluded (item: Number, fieldName: String): [String, Boolean] {
    let conditions = this.ratecard.feeRate.tierConditions
    if (conditions == null || conditions.length === 0 || item == null) { return [fieldName, false] }
    let validation: Array<boolean> = []
    conditions.forEach((condition: TierConditionsType) => {
      validation.push(Number(item) > condition.from && Number(item) < condition.to)
    })
    return [fieldName, validation.some((elem: boolean) => elem === true)]
  }
  get hasTableAtLeastOneCondition () {
    return this.ratecard.feeRate.tierConditions != null && this.ratecard.feeRate.tierConditions.length > 0
  }
  get hasTableAtLeastTwoConditions () {
    return this.ratecard.feeRate.tierConditions != null && this.ratecard.feeRate.tierConditions.length >= 2
  }
  get hasErrorInDataTable () {
    return !this.hasTableAtLeastOneCondition || !this.hasMinimalTierCondition || !this.hasContinuousTierConditions
  }
  get hasMinimalTierCondition () {
    if (this.showTable && this.hasTableAtLeastOneCondition) {
      return this.ratecard.feeRate.tierConditions[0].from !== null && this.ratecard.feeRate.tierConditions[0].from !== undefined &&
        Number(this.ratecard.feeRate.tierConditions[0].from) === 0
    }
    return true
  }
  get hasContinuousTierConditions () {
    if (this.hasTableAtLeastTwoConditions) {
      let conditions = this.ratecard.feeRate.tierConditions
      let validation: Array<boolean> = []
      conditions.forEach((condition: TierConditionsType, index: number) => {
        if (index + 1 >= conditions.length) { return }
        validation.push(Number(condition.to) === Number(conditions[index + 1].from))
      })
      return !validation.some((v: boolean) => v === false)
    }
    return true
  }
  get isTableComplete () {
    let v: Array<boolean> = []
    let ntcFrom = this.newTierConditions.from
    let ntcTo = this.newTierConditions.to
    if (!this.hasTableAtLeastOneCondition && Number(ntcFrom) === 0 && Number(ntcTo) === -1) {
      return true
    } else if (this.hasTableAtLeastOneCondition) {
      let index = 0
      let conditions = this.ratecard.feeRate.tierConditions
      let nextCondition: TierConditionsType = null
      if (Number(ntcFrom) === 0) {
        v.push(Number(ntcTo) === Number(conditions[index].from))
      }
      conditions.forEach((condition: TierConditionsType, index: number) => {
        if (index + 1 < conditions.length) {
          nextCondition = conditions[index + 1]
          if (Number(ntcFrom) < Number(nextCondition.from) && Number(ntcTo) > Number(condition.to)) {
            v.push(Number(condition.to) === Number(ntcFrom))
            v.push(Number(ntcTo) === Number(nextCondition.from))
          } else {
            v.push(Number(condition.to) === Number(nextCondition.from))
          }
        } else {
          if (Number(ntcTo) === -1) {
            v.push(Number(condition.to) === Number(ntcFrom))
          } else {
            v.push(Number(condition.to) === -1)
          }
        }
      })
      return !v.some((v: boolean) => v === false)
    } else {
      return false
    }
  }

  // ADD NEW TIER CONDITIONS
  showNewTierConditionsForm () {
    this.isNewTierConditionsFormOn = true
    this.prefillNextNewTierConditionsForm()
  }

  prefillNextNewTierConditionsForm () {
    if (this.ratecard.feeRate.tierConditions.length >= 1) {
      const lastIndex = this.ratecard.feeRate.tierConditions.length - 1
      this.newTierConditions.from = this.ratecard.feeRate.tierConditions[lastIndex].to
    }
  }

  // SAVE TIER CONDITIONS
  addNewTierConditionsLine (newItem: TierConditionsType) {
    let conditions = this.ratecard.feeRate.tierConditions
    let pos = 0
    if (conditions != null && conditions.length > 0) {
      if (Number(newItem.to) > Number(conditions[conditions.length - 1].from) || newItem.to === -1) {
        this.ratecard.feeRate.tierConditions.push(newItem)
      } else {
        pos = conditions.length - 1
        conditions.forEach((condition: TierConditionsType, index: number) => {
          if (Number(newItem.to) > Number(condition.from)) { pos = index + 1 }
        })
        this.ratecard.feeRate.tierConditions.splice(pos, 0, newItem)
      }
    } else {
      this.ratecard.feeRate.tierConditions = [newItem]
    }
  }
  saveNewTierConditions () {
    this.isNewTierConditionsFormOn = false
    this.refreshTierConditions = !this.refreshTierConditions
    let cloned = this.$commonUtils.copyInstance(this.newTierConditions)
    this.addNewTierConditionsLine(cloned)
    this.resetNewTierConditions()
  }
  saveAndNextNewTierConditions () {
    this.saveNewTierConditions()
    this.showNewTierConditionsForm()
  }
  resetNewTierConditions () {
    this.newTierConditions.from = null
    this.newTierConditions.to = null
    this.newTierConditions.rate = null
    this.newTierConditions.infinite = false
  }

  // CUSTOM RULES
  customRulesPercentage (item: any) {
    if (item == null || item.length === 0) { return true }
    return rules.percentage(item)
  }
  ruleTierConditionsInOrder () {
    if (this.newTierConditions.to !== -1 && this.newTierConditions.from && this.newTierConditions.to) {
      return Number(this.newTierConditions.from) < Number(this.newTierConditions.to)
    }
    return true
  }

  // ERROR
  isItemRequired (item: any) {
    if (!this.required) { return false }
    return item == null || item.length === 0 || !(rules.required(item) === true)
  }
  dataTableErrorMessages (): string {
    if (!this.hasTableAtLeastOneCondition) {
      return 'error: No condition'
    } else if (!this.hasMinimalTierCondition) {
      return 'error: No minimal condition'
    } else if (!this.hasContinuousTierConditions) {
      return 'error: No continuous conditions'
    }
  }
  errorMessage (item: any = null, fieldName: string = null) {
    // Chaining rules in order
    if (fieldName === 'DATA_TABLE' && this.required) {
      return this.dataTableErrorMessages()
    } else if (fieldName != null && ['FROM', 'TO'].includes(fieldName) && this.isConditionFieldIncluded(item, fieldName).includes(true)) {
      return 'This value is already included. Check the table above.'
    } else if (['FROM', 'TO'].includes(fieldName) && !this.ruleTierConditionsInOrder()) {
      return fieldName === 'TO' ? 'Field "To" must be greater than field "From"' : 'Field "From" must be smaller than field "To"'
    } else if (fieldName != null && fieldName === 'RATE' && this.rulesPercentageFlatRate(item)) {
      return 'This field must be between 0 and 100'
    } else if (item == null && !this.required) {
      return ''
    } else if (item != null && this.isItemRequired(item)) {
      return 'This field is required'
    }
    return ''
  }

  rulesPercentageFlatRate (item: number) {
    if (this.isFlatRate) {
      return rules.ruleIsBetween(item, 0, 100)
    }
    return this.customRulesPercentage(item) !== true
  }

  // GETTERS
  get hasFeeType () {
    return this?.ratecard?.feeRate.feeType != null
  }
  get isFlatRate () {
    return this?.ratecard?.feeRate?.feeType === 'FLAT_RATE'
  }
  get isFixedFee () {
    return this?.ratecard?.feeRate?.feeType === 'FIXED_FEE'
  }
  get isSowRate () {
    return this?.ratecard?.feeRate?.feeType === 'SOW'
  }
  get isTableRate () {
    return ['TIERED_RATE', 'VOLUME_RATE', 'SOW'].includes(this?.ratecard?.feeRate?.feeType)
  }
  get showTable () {
    return this.hasFeeType && !this.isFlatRate && !this.isFixedFee
  }
  get previousTierConditionTo () {
    if (this.ratecard.feeRate.tierConditions && this.ratecard.feeRate.tierConditions.length > 0) {
      return this.ratecard.feeRate.tierConditions[this.ratecard.feeRate.tierConditions.length - 1].to
    }
    return null
  }
  get currentMinimalTierCondition () {
    if (this.ratecard.feeRate.tierConditions != null && this.ratecard.feeRate.tierConditions.length > 0) {
      return this.ratecard.feeRate.tierConditions[0].from
    }
    return null
  }
  get currentMaximalTierCondition () {
    if (this.ratecard.feeRate.tierConditions != null && this.ratecard.feeRate.tierConditions.length > 0) {
      return this.ratecard.feeRate.tierConditions[this.ratecard.feeRate.tierConditions.length - 1].to
    }
    return null
  }
  get simulationFeeRate (): string | number {
    let feeType = this.ratecard.feeRate.feeType
    if ((feeType == null && !this.ratecard.billEvenWhenNoMediaCost) || feeType === 'FIXED_FEE') {
      return 'N/A'
    } else if (feeType === 'FLAT_RATE') {
      return this.flatRateSimulationFeeRate()
    } else if (['TIERED_RATE', 'SOW'].includes(feeType)) {
      return this.tieredAndSowSimulationFeeRate()
    } else if (feeType === 'VOLUME_RATE') {
      return this.volumeRateSimulationFeeRate()
    }
    return 'N/A'
  }
  get simulationPrice (): string | number {
    let feeType = this.ratecard.feeRate.feeType
    if (feeType == null && !this.ratecard.billEvenWhenNoMediaCost) {
      return 0
    } else if (feeType === 'FIXED_FEE') {
      return this.fixedFeeSimulationPrice()
    } else if (feeType === 'FLAT_RATE') {
      return this.flatRateSimulationPrice()
    } else if (['TIERED_RATE', 'SOW', 'VOLUME_RATE'].includes(feeType)) {
      return this.tableSimulationPrice()
    }
    return 'N/A'
  }

  // Simulation fee rates
  flatRateSimulationFeeRate () {
    let flatRate = Number(this.ratecard.feeRate.tierConditions)
    if (!isNaN(flatRate) && flatRate >= 0 && flatRate <= 100) {
      return roundNum(Number(this.ratecard.feeRate.tierConditions), 2)
    } else {
      return 'Enter a valid flat rate'
    }
  }
  tieredAndSowSimulationFeeRate () {
    let filteredCondition = this.ratecard.feeRate.tierConditions.filter((condition: TierConditionsType) => {
      if (condition.to !== -1) {
        return this.simulationMediaCost >= condition.from && this.simulationMediaCost <= condition.to
      } else {
        return this.simulationMediaCost >= condition.from
      }
    })
    if (filteredCondition.length > 0) {
      return roundNum(Number(filteredCondition[0].rate), 2)
    }
    return 'Enter at least one valid condition'
  }
  volumeRateSimulationFeeRate () {
    if ([NaN, 0].includes(Number(this.ratecard.minBilledOptimizedMediaCost)) && [NaN, 0].includes(Number(this.simulationMediaCost))) {
      return 'N/A'
    }
    // div 1 => (MIN(tier_N.borne_sup,MAX(MAX(min_billed_optimized_media_cost.value;simulation_media_cost.value),tier_N.borne_inf))-tier_N.borne_inf)*tier_N.rate
    let div1 = 0
    this.ratecard.feeRate.tierConditions.forEach((condition: TierConditionsType) => {
      let a = Math.max(this.ratecard.minBilledOptimizedMediaCost, this.simulationMediaCost)
      let b = Math.max(a, Number(condition.from))
      let c = condition.to !== -1 ? Math.min(b, Number(condition.to)) : b
      div1 += (c - Number(condition.from)) * (Number(condition.rate) / 100)
    })
    // div 2 => MAX(min_billed_optimized_media_cost.value;simulation_media_cost.value)
    let div2 = Math.max(this.ratecard.minBilledOptimizedMediaCost, this.simulationMediaCost)
    let result = div1 / div2
    // SUM((MIN(tier_N.borne_sup,MAX(MAX(min_billed_optimized_media_cost.value;simulation_media_cost.value),tier_N.borne_inf))-tier_N.borne_inf)*tier_N.rate )/MAX(min_billed_optimized_media_cost.value;simulation_media_cost.value)
    return roundNum(result * 100, 2)
  }

  // Simulation prices
  fixedFeeSimulationPrice () {
    return this.ratecard.feeRate.tierConditions.length > 0 ? this.ratecard.feeRate.tierConditions : 'N/A'
  }
  flatRateSimulationPrice () {
    if (Number(this.ratecard.feeRate.tierConditions) === 0) { return 'Enter a valid flat rate' }
    if (Math.max(this.ratecard.minBilledOptimizedMediaCost, this.simulationMediaCost) === 0) { return 'N/A' }
    return roundNum(Math.max(this.ratecard.minBilledOptimizedMediaCost, this.simulationMediaCost) * (this.ratecard.feeRate.tierConditions / 100), 2)
  }
  tableSimulationPrice () {
    // MAX(min_billed_optimized_media_cost.value;simulation_media_cost.value)*simulationFeeRate
    if (isNaN(Number(this.simulationFeeRate))) { return 'N/A' }
    let result = roundNum(Math.max(this.ratecard.minBilledOptimizedMediaCost, this.simulationMediaCost) * Number(this.simulationFeeRate), 2)
    return !isNaN(Number(result)) ? result : 'N/A'
  }
}

