
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Model, Prop, PropSync, Watch } from 'vue-property-decorator'
import { Autoplugs } from '../../../../../types/autoplugs_type'
import _ from 'lodash'
import { AutoplugInputType } from '@/components/KeystoneV2/Form/Autoplug/AutoplugForm.vue'

@Component({})
export default class SelectMultiple extends Vue {
  @Model('change', { required: true })
  readonly list!: any[]

  @Prop()
  label: string

  @Prop()
  items: TextValue[]

  @Prop({ default: false })
  required: boolean

  @Prop()
  inputType: AutoplugInputType

  @Prop()
  loading: boolean

  @PropSync('searchProp')
  search: string

  @Prop({ type: Function, default: (item: any) => false })
  itemDisabled: (item: TextValue) => boolean

  @Emit('change')
  emitChange (value: any[]) {}

  fixComboDone = false

  remove (value: string | number) {
    let index = null
    if (typeof value === 'string') {
      index = (this.list as string[]).indexOf(value)
    } else if (typeof value === 'number') {
      index = (this.list as number[]).indexOf(value)
    }
    if (index !== -1 && index !== null) {
      let list = _.cloneDeep(this.list)
      list.splice(index, 1)
      this.emitChange(list)
    } else {
      console.warn('Can not find item to remove')
    }
  }

  multipleSelection (item: TextValue) {
    this.emitChange([...this.list, item.value])
    console.log(this.list)
  }

  getItemValueCombobox (itemId: number) {
    let item = this.items.find(item => Number(item.value) === itemId)
    return item ? item.value : itemId + '(Not registered id)'
  }

  toggle () {
    this.$nextTick(() => {
      if (this.allSelected) {
        this.emitChange([])
      } else {
        this.emitChange(this.items.map(i => i.value))
      }
    })
  }

  handleInput (listValue: any[]) {
    let toReturn = []
    for (let i in listValue) {
      let e = listValue[i]
      let value
      if (typeof e === 'string' || typeof e === 'number') {
        console.log('string or number - do something with user value')
        value = e
      } else {
        console.log('object - do something with object, value selected from list')
        console.log(e.value)
        value = e.value
      }
      toReturn.push(value)
    }
    this.emitChange(toReturn)
  }

  requiredErrorMessage (): string[] {
    if (!this.required) {
      return []
    }
    return this.list && this.list.length ? [] : ['Required field']
  }

  get allSelected () {
    if (this.isCombobox || !this.list) {
      return false
    }
    return this.list.length === this.items.length
  }
  get someSelected () {
    if (this.isCombobox || !this.list) {
      return false
    }
    return this.list.length > 0 && !this.allSelected
  }
  get icon () {
    if (this.allSelected) return 'check_box'
    if (this.someSelected) return 'indeterminate_check_box'
    return 'check_box_outline_blank'
  }
  get isCombobox () {
    return this.inputType === 'combobox'
  }
}
