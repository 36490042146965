export const callEditFormMixin = {
  data: function () {
    return {
      callNewForm: false,
      callEditForm: false,
      callFormEntity: null
    }
  },
  methods: {
    activeCallNewForm (entity) {
      this.callFormEntity = entity
      this.callNewForm = true
    },
    activeCallEditForm (entity) {
      this.callFormEntity = entity
      this.callEditForm = true
    },
    /***
     * if "activeCallNewForm" or "activeCallEditForm" have been called, calling this function will
     * open the form for the entity passed in argument in these function. Otherwise, do nothing
     * When called, reset the state (callEditForm or callNewForm) to false.
     * @param newMutation
     * @param editMutation
     */
    callEditFormFunc (newMutation, editMutation) {
      if (this.callEditForm) {
        this.$store.commit(editMutation, this.callFormEntity)
        this.callEditForm = false
      } else if (this.callNewForm) {
        this.$store.commit(newMutation, this.callFormEntity)
        this.callNewForm = false
      }
    }
  }
}
