import { TAB } from '@/components/KeystoneV2/commonConstantKeystone'

export const keystoneTabViewMixin = {
  data: function () {
    return {}
  },
  methods: {
    async callApiIfRightTab () {
      let currentTab = this.$store.getters.getCurrentTab
      if (currentTab === TAB[this.entityType]) {
        await this.callApi()
      }
    },
    async callApi () {
      console.warn('Not implemented')
    }
  },
  watch: {
    '$store.getters.getCurrentTab': {
      immediate: false,
      deep: true,
      handler: async function () {
        await this.callApiIfRightTab()
      }
    }
  }
}
