

import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import * as rules from '../../../../rules/rules'

@Component({})
export default class MailChipInput extends Vue {
    @Prop({ required: true }) label: string
    @Prop({ required: true }) model: Array<string>
    @Prop({ default: false }) disabled: boolean
    @Prop({ default: false }) required: boolean
    @Prop({ required: false, default: false }) unique: boolean

    rules = rules
    value: Array<string>
    MAX_MAIL_LENGTH = 16

    isFocused: boolean = false

    created () {
      this.reload(null)
    }

    customRequireForArray (item: any): string | boolean {
      if (this.value != null && this.value.length === 0) {
        return 'Field is required'
      }
      return true
    }

    pastedMails (event: any) {
      const data: Array<string> = event.clipboardData.getData('text').split(/[ ,]+/)
      data.forEach((mail: string) => {
        this.value.push(mail)
      })
      this.checkInvoiceMail(this.value)
    }

    checkInvoiceMail (items: Array<string>) {
      // Used for Reseller mail
      if (this.unique && items.length > 1) {
        items.splice(1, 1)
      }
      items.forEach(item => {
        if (!rules.emailPattern.test(String(item).toLowerCase())) {
          let index = items.indexOf(item, 0)
          if (index > -1) {
            items.splice(index, 1)
          }
        }
      })
      this.$emit('update:model', this.value)
    }

    removeMailItem (item: string) {
      let index = this.value.indexOf(item)
      if (index > -1) {
        this.value.splice(index, 1)
      }
    }

    displayMailAddressInChip (mail: string) {
      let mainPart = mail.substring(0, mail.indexOf('@'))
      const endPart = mail.substring(mail.indexOf('@'), mail.length)
      if (mainPart.length > this.MAX_MAIL_LENGTH) {
        mainPart = mainPart.substring(0, this.MAX_MAIL_LENGTH - 3) + '...'
      }
      return mainPart + endPart
    }

    copyMailAddresses () {
      if (this.value.length === 0) { return }
      navigator.clipboard.writeText(this.value.join(', '))
        .then((): void => {
          this.showSnackbarCopyDone()
        })
        .catch(e => console.error(e))
    }

    showSnackbarCopyDone () {
      const barConfig = {
        type: 'success',
        message: 'Mail addresses successfully copied to clipboard!',
        color: 'green'
      }
      this.$store.commit('setSnackBarConfig', barConfig)
      this.$store.commit('setActiveSnackBar', true)
    }

    errorMessage (item: any) {
      if (item === 'undefined' || item === null || !this.required) {
        return ''
      }
      let ruleCheck: string | boolean = this.customRequireForArray(item)
      let toReturn = ruleCheck !== true ? 'Field is required' : ''
      return toReturn
    }

    reload (value: Array<string> = null) {
      this.value = value != null ? value : (this.model ? this.model : [])
    }
}

