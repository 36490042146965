<template>
  <v-toolbar outlined color="white">
    <v-toolbar-items :class="'pt-2'">
      <v-text-field
        class="align-center"
        label="search"
        v-model.trim="search"
        prepend-icon="search"
      >
      </v-text-field>
    </v-toolbar-items>

    <v-toolbar-items>
      <div class="pa-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="emitRefresh">
              <v-icon>
                refresh
              </v-icon>
            </v-btn>
          </template>
          <span>
          Refresh
        </span>
        </v-tooltip>
      </div>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <v-toolbar-items v-if="!hideNew">
      <v-btn :disabled="disabledNew" text color="primary" @click="openNew(null)">
        NEW {{entityType !== 'siege' ? entityType : 'seat'}}
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import * as keystoneV2Utils from '../../../utils/keystoneV2Utils'

export default {
  name: 'TopKeystoneToolbar',
  props: {
    value: {
      type: String,
      default: ''
    },
    openNew: {
      type: Function
    },
    entityType: {
      validator: keystoneV2Utils.validateEntityType
    },
    disabledNew: {
      type: Boolean,
      default: false
    },
    hideNew: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      search: '',
      isMounted: false
    }
  },
  mounted: function () {
    this.search = this.value
    this.getQueryParams()
    this.isMounted = true
  },
  methods: {
    emitRefresh () {
      this.$emit('refresh')
    },
    getQueryParams () {
      let type = this.$route.query.type
      let search = this.$route.query.search

      if (type === this.entityType && search !== undefined) {
        this.$store.commit('setCurrentTab', keystoneV2Utils.getTabForEntity(this.entityType))
        this.search = search
      }
    },
    setQueryParams (search) {
      // for avoid duplicate navigation error
      if (this.$route.query.type === this.entityType && this.$route.query.search === search) {
        return
      }
      this.$router.push({ name: 'keystoneV2', query: { type: this.entityType, search: search } })
    }
  },
  watch: {
    value (val) {
      if (val !== this.search) {
        this.search = val
      }
    },
    search (val) {
      if (this.isMounted) {
        this.setQueryParams(val)
      }
      this.$emit('input', val)
    },
    '$store.getters.getCurrentTab': function (currentTab) {
      if (currentTab === keystoneV2Utils.getTabForEntity(this.entityType)) {
        this.setQueryParams(this.search)
      }
    }
  }
}
</script>

<style scoped>

</style>
