import { camelToSnake } from '../../../utils/commonUtils'

/**
 * https://trello.com/c/okdODlKD/779-keystone-v2-modif-du-module-raise-margin
 * https://trello.com/c/YEyeDtkZ/979-keystone-modif-du-module-de-margin-raise
 */

export class MarkupModuleModel {
  constructor (data = {}) {
    this.state = data != null && data.state != null ? data.state : 'weekly_alerts'
    this.granularity = data != null && data.granularity != null ? data.granularity : 'insertion_order_id'
    this.modificationMode = data != null ? data.modification_mode : 'manual'
    this.maxAvgMrkpDuringPeriod = data != null && data.max_avg_mrkp_during_period != null ? data.max_avg_mrkp_during_period : 1000
    this.minNominalMrkp = data != null && data.min_nominal_mrkp != null ? data.min_nominal_mrkp : 0
    this.useFirstPushMrkpAsMinNominal = data != null && data.use_first_push_mrkp_as_min_nominal != null ? data.use_first_push_mrkp_as_min_nominal : true
    this.maxNominalMrkp = data != null && data.max_nominal_mrkp != null ? data.max_nominal_mrkp : 3000
    this.kpiEvalPeriodInDays = data != null && data.kpi_eval_period_in_days != null ? data.kpi_eval_period_in_days : 1
    this.useBillingPeriodAsKpiEvalPeriod = data != null && data.use_billing_period_as_kpi_eval_period != null ? data.use_billing_period_as_kpi_eval_period : true
    this.avgMrkpEvalPeriodInDays = data != null && data.avg_mrkp_eval_period_in_days != null ? data.avg_mrkp_eval_period_in_days : 1
    this.useBillingPeriodAsAvgMrkpEvalPeriod = data != null && data.use_billing_period_as_avg_mrkp_eval_period != null ? data.use_billing_period_as_avg_mrkp_eval_period : true
    this.controlMrkpAtPofLvl = data != null && data.control_mrkp_at_pof_lvl != null ? data.control_mrkp_at_pof_lvl : false
    this.avgDailyMrkpAtPofLvl = data != null && data.avg_daily_mrkp_at_pof_lvl != null ? data.avg_daily_mrkp_at_pof_lvl : null
    this.markupFeeDesignationKeywords = data != null && data.markup_fee_designation_keywords != null ? data.markup_fee_designation_keywords : null
    this.noKeywords = data != null && data.no_keywords != null ? data.no_keywords : null
    // set default value of action with actionSelect to trigger the update of the other fields in case of the value is ratio
    this.actionSelect = data != null && data.action != null ? data.action : 'margin'
  }

  setToDefaultValues () {
    this.state = 'off'
    this.granularity = 'insertion_order_id'
    this.action = 'margin'
    this.modificationMode = 'manual'
    this.maxAvgMrkpDuringPeriod = 1000
    this.minNominalMrkp = 0
    this.useFirstPushMrkpAsMinNominal = true
    this.maxNominalMrkp = 3000
    this.kpiEvalPeriodInDays = 1
    this.useBillingPeriodAsKpiEvalPeriod = true
    this.avgMrkpEvalPeriodInDays = 1
    this.useBillingPeriodAsAvgMrkpEvalPeriod = true
    this.controlMrkpAtPofLvl = false
    this.avgDailyMrkpAtPofLvl = null
    this.markupFeeDesignationKeywords = null
    this.noKeywords = false
  }

  /**
   * @return {MarkupModule}
   */
  getObjectForApi () {
    let toSnakeCase = {}

    for (let i in this) {
      if (!this.hasOwnProperty(i)) {
        continue
      }
      // convert empty values to null
      if (this[i] === undefined || String(this[i]).trim() === '') {
        toSnakeCase[camelToSnake(i)] = null
      } else {
        toSnakeCase[camelToSnake(i)] = this[i]
      }
    }

    return toSnakeCase
  }

  get actionSelect () {
    return this.action
  }

  set actionSelect (value) {
    if (value === 'ratio') {
      this.controlMarkupAtPofLvl = false
      this.avgDailyMarkupAtPofLvl = null
      this.maxAvgMrkpDuringPeriod = null
      this.useBillingPeriodAsAvgMrkpEvalPeriod = false
    }
    this.action = value
  }
}
