
import { Prop, PropSync } from 'vue-property-decorator'
import Vue from 'vue'
import Component from 'vue-class-component'

export const TYPE_CSV = 'text/csv'
@Component
export default class InputFile extends Vue {
  @Prop()
  acceptType: string
  @Prop()
  buttonValue: string
  @Prop()
  colorBackground: string
  @PropSync('data')
  dataInput: { [key:string] : string }
  @PropSync('typeFileCheck')
  inputFileTypeOk: boolean

  get textTooltip () {
    return 'Only ' + this.acceptType.split('/')[1] + ' file type accepted !'
  }

  selectFIle () {
    document.getElementById('importFile').click()
  }

  handleFile (event: any) {
    if (this.acceptType === TYPE_CSV) {
      this.handleCsvUpload(event)
    }
  }

  handleCsvUpload (event : any) {
    if (event.target.files) {
      const file = event.target.files[0]
      if (file.type !== TYPE_CSV) {
        this.inputFileTypeOk = false
      } else {
        const reader = new FileReader()
        reader.onload = (event) => {
          const csvData = (event.target.result as string)
          const arrayData = this.parseCsvData(csvData)
          const serializedData = { 'external_ids': arrayData.join() }
          this.inputFileTypeOk = true
          this.dataInput = serializedData
        }
        reader.readAsText(file)
      }
    }
  }

  parseCsvData (csvData : string) {
    const rows = csvData.split('\n').filter(element => element)
    return rows.map((row) => {
      const advertiserId = row.split(',')
      return advertiserId.map((elem) => {
        if (elem !== '') {
          return elem.trim()
        }
      })
    })
  }
}

