export const classLabelValueMixin = {
  props: {
    classLabel: {
      type: String,
      default: ''
    },
    classValue: {
      type: String,
      default: ''
    }
  }
}
